import { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UserInfo } from 'recoil/auth';
import { IHeaderBar } from './HeaderBar.type';
import { SubTabBar, Footer } from 'domains';
import { subTabBarItems } from 'navigations/data';
import { MypageDropdown } from 'common/components';
import axios, { AxiosError } from 'axios';
import { createBrowserHistory } from "history";
import HamburgerMenu from 'domains/Icon/Icon';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled as styledMui } from '@mui/system';
import { api } from 'common/api';

const RESPONSIVE_THRESHHOLD = 1353 ; 
const MOBILE_THRESHHOLD = 480 ; 

const muiTheme = createTheme({
    palette : {
        primary : {
            main : "#0D6637"
        }
    }
})

const ImageBanner = styled('div')({
    width: '100%',
    height: '200px',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // background: '#f5f5f5',
    background : 'white',
  });
  
  const BannerImage = styled('img')({
    width: '50%',
    height: 'auto',
    position : "relative",
    left : "-23vw",
  });
  
  const CloseButton = styled(IconButton)({
    position: 'absolute',
    left: 0,
    top: 0,
  });

  
export const history = createBrowserHistory();

const boundaryWidth = process.env.REACT_APP_BOUNDARY_WIDTH;

const BASEURL = 'https://www.gardenersclub.co.kr/api';
const TOKEN = localStorage.getItem('accesstoken');

const HeaderBar: React.FC<IHeaderBar> = (props) => {
    const { children } = props;
    const [scrollDownToggle, setScrollDownToggle] = useState<boolean>(false);
    const [prevPosY, setPrevPosY] = useState<number>(0);
    const [crntPosY, setCrntPosY] = useState<number>(0);
    const [fadeAnim, setFadeAnim] = useState<any>();
    const [subTabVisible, setSubTabVisible] = useState<boolean>(true);
    const [overPage, setOverPage] = useState<number>(0);
    const [crntPage, setCrntPage] = useState<number>(0);
    const [crntPath, setCrntPath] = useState<string>('');
    const [loginStatus, setLoginStatus] = useRecoilState(UserInfo);
    const { isLogin } = useRecoilValue(UserInfo);
    const [isHamburgerMenuOn, setIsHamburgerMenuOn] = useState(false);
    const [isShowSideDetail, setIsShowSideDetail] = useState(false);
    const detailRefs = useRef<HTMLDetailsElement>(null)

    const loc = useLocation();
    const nav = useNavigate();
    const isMobile = navigator.userAgent.toLowerCase().includes(`mobile`)

    const scrollHandler = () => {
        setCrntPosY(window.scrollY);
    };

    useEffect(() => {
        if(detailRefs.current) {
            detailRefs.current.addEventListener("toggle", e => {
                e.preventDefault();
            })
        }        
    }, [detailRefs.current]);

    useEffect(() => {
        scrollDownToggle === true ? setFadeAnim(headerFadeOut) : setFadeAnim(headerFadeIn);
    }, [scrollDownToggle]);

    useEffect(() => {
        setFadeAnim(null);
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    useEffect(() => {
        setOverPage(crntPage);
    }, [crntPage]);

    const getRefreshToken = async () => {
        try {
            const response = await axios.get(`${BASEURL}/api/login/token/renew`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('refreshtoken')}`,
                },
            });
            console.log(response.data.data);
            if (!response.data.data) {
                localStorage.clear();
                setLoginStatus({ ...loginStatus, isLogin: false });
                nav('/login');
            } else {
                localStorage.setItem('accesstoken', response.data.data);
                window.location.replace('/');
            }
        } catch (e) {
            localStorage.clear();
            setLoginStatus({ ...loginStatus, isLogin: false });
            nav('/login');
            console.log(e);
        }
    };

    const StyledTextForCoumminity = styled.div`
        ::before {
                content: '';
                position: absolute;
                width: 40px;
                height: 40px;
                right: 0px;
                top : -0px ;
                /* transition: transform 0.3s; */
                transform: rotate(90deg);
                background-image: url('/btnArrow.png');
            }
    `;

    const Sidebar = styled.div`
        overflow: auto;
        position: fixed;        
        display: ${isHamburgerMenuOn ? 'block' : 'none'};
        /* left: ${isHamburgerMenuOn ? '0' : `${100-84.7}vw`}; */
        right: ${isHamburgerMenuOn ? '0' : `${100-84.7}vw`};
        top : 0 ;
        box-sizing: border-box;
        /* width: 100vw; */
        width: 84.7vw;
        /* height: 100vh; */
        height: 100%;
        /* color: white; */
        background-color: white;
        /* transition: left 0.3s ease-in-out; */
        padding: 8% 7% 5% 7%;
        z-index: 9999;

        summary {
            font-size: 6vw;
            font-weight: 1000;
        }

        hr {
            /* padding: 10vw 0; */
            /* margin: 10vw 0; */
        }

        details {
            position: relative;
        }

        details summary {
            list-style: none;
        }

        details summary::-webkit-details-marker {
            display: none;
        }

        details summary::before {
            content: '';
            position: absolute;
            /* display: inline-block; */
            width: 40px;
            height: 40px;
            right: 0px;
            top : -0px ;
            /* transition: transform 0.3s; */
            transform: rotate(90deg);
            background-image: url('/btnArrow.png');
        }

        details[open] summary::before {
            /* transform: rotate(90deg); */
            transform: rotate(270deg);
            top : -2vw ;
        }
    `;
      

    const logout = async () => {
        try {
            await api.get(`${BASEURL}/api/logout`);
            localStorage.clear();
            setLoginStatus({ ...loginStatus, isLogin: false });
            window.location.replace('/');
        } catch (e) {
            console.log(e);
        }
    };

    const SibebarLayout = () => {
        return (
            <ThemeProvider theme={muiTheme}>
                {isLogin
                ? (
                    <div className="" style={{display : "flex", gap : ".8em", alignItems : "center", marginTop : "1.5em"}}>
                        <img src={localStorage?.profileUrl} style={{width : "12%", borderRadius : "50%"}} />
                        <div className="" style={{fontSize : "4vw", fontWeight : "1000"}}>{`@${localStorage?.nickName}`}</div>
                    </div>                    
                )
                : (
                    <div className="" style={{display : "flex", width : "100%", boxSizing : "border-box" ,gap : "3%", padding : "5vw 0 0 0 "}}>
                        <Link to="/login" style={{width : "48%", textDecoration: 'none', color: '#272727' }}>
                            <Button variant="outlined"  style={{width : "100%", fontSize : "5vw", lineHeight : "2.5em", fontWeight : 1000 }}>로그인</Button>
                        </Link>
                        <Link to="/login" style={{width : "48%", textDecoration: 'none', color: '#272727' }}>
                            <Button variant="contained" style={{width : "100%", fontSize : "5vw", lineHeight : "2.5em", fontWeight : 1000}}>회원가입</Button>
                        </Link>
                    </div>                    
                )
                }
                <div className="" style={{display : "flex", width : "100%", boxSizing : "border-box" ,gap : "3%", padding : "3vw 0 "}}>                                         
                </div>
                <hr/>                
                <div className="" style={{margin : "10vw 0"}}></div>
                <div className="" style={{overflow : "auto"}}>
                    <div className="" style={{display : "flex", justifyContent : "space-between", alignItems : "center"}} onClick={() => setIsShowSideDetail(!isShowSideDetail)}>
                        <div className=""  style={{fontSize : "4.5vw", fontWeight : "1000", paddingTop : "0vw" }}>커뮤니티</div>
                        <img src='/btnArrow.png' style={{width : "initial" , height :"6vw", transform : `${isShowSideDetail ? "rotate(90deg)" : ""}`}}></img>                   
                    </div>
                    {isShowSideDetail &&
                    <div className="" style={{display : "flex", flexDirection : "column", gap : "8vw", padding : "8vw 0 0 0"}}>
                            <StyledMenuItemTextForSidebar color={'#0d6637'}>식물사전</StyledMenuItemTextForSidebar>
                            <div style={{fontSize : "4.5vw", fontWeight : "bolder", color : "#D9D9D9"}}>인기</div>
                            <div style={{fontSize : "4.5vw", fontWeight : "bolder", color : "#D9D9D9"}}>팔로잉</div>
                            <div style={{fontSize : "4.5vw", fontWeight : "bolder", color : "#D9D9D9"}}>사진</div>
                            <div style={{fontSize : "4.5vw", fontWeight : "bolder", color : "#D9D9D9"}}>매거진</div>
                            <div style={{fontSize : "4.5vw", fontWeight : "bolder", color : "#D9D9D9"}}>Q&A</div>                                                
                    </div>                                
                    }
                    <div style={{fontSize : "4.5vw", fontWeight : "1000", paddingTop : "7vw" , marginBottom : "20vw" }}>스토어</div>
                </div>
                <div style={{fontSize : "4vw", fontWeight : "1000", padding : "6vw 0", position : "fixed", bottom : "0" , borderTop : "2px solid #D6D6D6", width : "85%", boxSizing : "border-box" , marginBottom : "0em", background : "white" }}
                    onClick={() => {
                        logout()
                    }}
                >
                    로그아웃
                </div>
            </ThemeProvider>
        )
    }

    const SidebarHeader = () => {
        return (
            <div className="" style={{display : "flex", justifyContent : "space-between", alignItems : "center"}}>
                <img src="/Gardener.png" style={{width : "initial", height : "10vw"}}/>
                <div className="" style={{position : "relative"}}>
                    {/* <img src="/Gardener.png" style={{position : "absolute" , left : "0vw",  width : "initial", height : "10vw"}}/> */}
                    <img onClick={() => setIsHamburgerMenuOn(prev => !prev)} src="/btnCloseWhite.png" 
                    // btnCloseWhite.png
                        style={{position : "fixed", top : "2.2em", left : "5vw" , width : "initial", height : "5vw"}}></img>
                        {/* style={{position : "relative", top : "0", right : "-1vw" , width : "initial", height : "10vw"}}></img> */}
                </div>
            </div>
        )
    }
    if (isLogin === true) {
        //로그인 후, 식물사전 피드 페이지
        if (
            loc.pathname === '/community/question/new' ||
            loc.pathname === '/community/question/new/' ||
            loc.pathname === '/upload' ||
            loc.pathname === '/upload/photo' ||
            loc.pathname === '/upload/video' ||
            loc.pathname === '/community/photo/edit' ||
            loc.pathname === '/community/video/edit' ||
            loc.pathname === '/community/following'
        )
            return (
                <StyledContainer>
                    <StyledContentContainer>
                        <StyledContentBlock>{children}</StyledContentBlock>
                    </StyledContentContainer>
                    <Footer />
                </StyledContainer>
            );
        else
            return (
                <StyledContainer>                    
                    <StyledTabsContainer
                        onMouseLeave={() => {
                            setOverPage(crntPage);
                            setScrollDownToggle(!subTabVisible);
                        }}
                    >
                        <EventBarContainer>
                            <a
                                href="https://smartstore.naver.com/gardenersclub/notice/list?cp=1"
                                target="blank"
                                style={{ textDecoration: 'none' }}
                            >
                                <StyledHeaderEventBar color={'#FFFFFF'}>리뷰 이벤트 참여하기</StyledHeaderEventBar>
                            </a>
                        </EventBarContainer>

                        <StyledHeaderBarContainer
                            fadeAnim={fadeAnim}
                            onMouseEnter={() => {
                                setSubTabVisible(!scrollDownToggle);
                                setScrollDownToggle(false);
                            }}
                        >
                            <StyledHeaderBar>
                                <StyledTitleBlock>
                                    <Link
                                        to="/community/dictionary"
                                        style={{ textDecoration: 'none' }}
                                        onClick={() => {
                                            setCrntPage(0);
                                            setOverPage(0);
                                            setScrollDownToggle(false);
                                            setSubTabVisible(true);
                                        }}
                                    >
                                        
                                        <StyledLogoImg src="/gardenersLogo.png" />
                                    </Link>
                                </StyledTitleBlock>
                                <HamburgerMenu handleclick={setIsHamburgerMenuOn} />
                                <Sidebar>       
                                    <SidebarHeader />                                 
                                    {/* <div className="" style={{display : "flex", justifyContent : "space-between", alignItems : "center"}}>
                                        <img src="/Gardener.png" style={{width : "50%", height : "initial"}}/>
                                        <img onClick={() => setIsHamburgerMenuOn(prev => !prev)} src="/btnClose.png" style={{width : "15%"}}></img>
                                    </div> */}
                                    <SibebarLayout/>
                                    {/* <ThemeProvider theme={muiTheme}>
                                        <div className="" style={{display : "flex", gap : ".8em", alignItems : "center", marginTop : "1.5em"}}>
                                            <img src={localStorage?.profileUrl} style={{width : "12%", borderRadius : "50%"}} />
                                            <div className="" style={{fontSize : "6vw", fontWeight : "1000"}}>{`@${localStorage?.nickName}`}</div>
                                        </div>
                                        <div className="" style={{display : "flex", width : "100%", boxSizing : "border-box" ,gap : "3%", padding : "5vw 0 "}}>                                         
                                        </div>
                                        <hr/>
                                        <div className="" style={{margin : "10vw 0"}}></div>
                                        <details ref={detailRefs}>
                                            <summary>
                                                커뮤니티
                                            </summary>
                                            <div className="" style={{display : "flex", flexDirection : "column", gap : "8vw", padding : "8vw 0 0 0"}}>
                                                <StyledMenuItemTextForSidebar color={'#0d6637'}>식물사전</StyledMenuItemTextForSidebar>
                                                <div style={{fontSize : "6vw", fontWeight : "bolder", color : "#D9D9D9"}}>인기</div>
                                                <div style={{fontSize : "6vw", fontWeight : "bolder", color : "#D9D9D9"}}>팔로잉</div>
                                                <div style={{fontSize : "6vw", fontWeight : "bolder", color : "#D9D9D9"}}>사진</div>
                                                <div style={{fontSize : "6vw", fontWeight : "bolder", color : "#D9D9D9"}}>매거진</div>
                                                <div style={{fontSize : "6vw", fontWeight : "bolder", color : "#D9D9D9"}}>Q&A</div>                                                
                                            </div>
                                        </details>
                                        <div style={{fontSize : "6vw", fontWeight : "1000", paddingTop : "7vw" }}>스토어</div>
                                        <div style={{fontSize : "4.5vw", fontWeight : "1000", paddingTop : "6vw", position : "absolute", bottom : "0" , borderTop : "2px solid #D6D6D6", width : "85%", marginBottom : "1em" }}
                                            onClick={() => {
                                                logout()
                                            }}
                                        >
                                            로그아웃
                                        </div>
                                    </ThemeProvider> */}
                                </Sidebar>
                                <StyledMenuItemsContainer style={{ marginLeft: '50px' }}>
                                    <StyledMenuItemBlock>
                                        <Link
                                            to="/community/dictionary"
                                            style={{ textDecoration: 'none' }}
                                            onClick={() => {
                                                setScrollDownToggle(false);
                                                setSubTabVisible(true);
                                            }}
                                        >
                                            <StyledMenuItemText color={'#0d6637'}>커뮤니티</StyledMenuItemText>
                                        </Link>
                                    </StyledMenuItemBlock>
                                    <LineStyle />
                                    <StyledMenuItemBlock>
                                        <a
                                            href="https://smartstore.naver.com/gardenersclub"
                                            target="blank"
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <StyledMenuItemText color={'#898989'}>스토어</StyledMenuItemText>
                                        </a>
                                    </StyledMenuItemBlock>
                                </StyledMenuItemsContainer>

                                <StyledButtonsCotainer>
                                    <MypageDropdown />
                                </StyledButtonsCotainer>
                            </StyledHeaderBar>
                        </StyledHeaderBarContainer>
                        <StyledSubTabBarBlock>
                            {crntPath === 'mypage' ? (
                                <></>
                            ) : crntPath === 'userpage' ? (
                                <></>
                            ) : (
                                <SubTabBar
                                    visible={!scrollDownToggle}
                                    overPage={overPage}
                                    crntPage={crntPage}
                                    setScrollDownToggle={setScrollDownToggle}
                                    setSubTabVisible={setSubTabVisible}
                                    items={subTabBarItems}
                                />
                            )}
                        </StyledSubTabBarBlock>
                    </StyledTabsContainer>
                    <StyledContentContainer>
                        {crntPath === 'mypage' ? (
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                }}
                            >
                                <div style={{ marginRight: 64 }}>{/* <Profile /> */}</div>
                                <div>{children}</div>
                            </div>
                        ) : crntPath === 'userpage' ? (
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'center',
                                }}
                            >
                                <div style={{ marginRight: 64 }}>{/* <UserProfile /> */}</div>
                                <div>{children}</div>
                            </div>
                        ) : (
                            <StyledContentBlock>{children}</StyledContentBlock>
                        )}
                    </StyledContentContainer>
                    <Footer />
                </StyledContainer>
            );
    } else {
        //로그인 페이지 && 추가 입력 페이지
        if (loc.pathname === '/login' || loc.pathname === '/register')
            return (
                <StyledLoginContainer>                   
                    <StyledTabsContainer>
                        <StyledHeaderBarContainer
                            fadeAnim={fadeAnim}
                            onMouseEnter={() => {
                                setSubTabVisible(!scrollDownToggle);
                                setScrollDownToggle(false);
                            }}
                        >
                            <StyledHeaderBar>                                
                                <StyledTitleBlock>
                                    <Link
                                        to="/community/dictionary"
                                        style={{ textDecoration: 'none' }}
                                        onClick={() => {
                                            setCrntPage(0);
                                            setOverPage(0);
                                            setScrollDownToggle(false);
                                            setSubTabVisible(true);
                                        }}
                                    >
                                        {isMobile
                                        ? <ImageBanner style={{display : "flex", justifyContent : "space-between"}}>
                                                <CloseButton onClick={() => {1}}>
                                                    <img src="/prev.png" style={{}} ></img>
                                                    {/* <CloseIcon /> */}
                                                </CloseButton>
                                                <BannerImage src="/Gardener.png" alt="Banner"  />
                                            </ImageBanner>
                                        : <StyledLogoImg src="/gardenersLogo.png" />
                                        }                                        
                                    </Link>
                                </StyledTitleBlock>
                            </StyledHeaderBar>
                        </StyledHeaderBarContainer>
                    </StyledTabsContainer>
                    <StyledLoginContentContainer>
                        <StyledContentBlock>{children}</StyledContentBlock>
                    </StyledLoginContentContainer>
                    {isMobile === false && <Footer />}
                    {/* <Footer /> */}
                </StyledLoginContainer>
            );
        //비로그인 식물 사전피드 페이지
        else
            return (
                <StyledContainer>
                    <StyledTabsContainer
                        onMouseLeave={() => {
                            setOverPage(crntPage);
                            setScrollDownToggle(!subTabVisible);
                        }}
                    >
                        <EventBarContainer>
                            <a
                                href="https://smartstore.naver.com/gardenersclub/notice/list?cp=1"
                                target="blank"
                                style={{ textDecoration: 'none' }}
                            >
                                <StyledHeaderEventBar color={'#FFFFFF'}>리뷰 이벤트 참여하기</StyledHeaderEventBar>
                            </a>
                        </EventBarContainer>

                        <StyledHeaderBarContainer
                            fadeAnim={fadeAnim}
                            onMouseEnter={() => {
                                setSubTabVisible(!scrollDownToggle);
                                setScrollDownToggle(false);
                            }}
                        >
                            <StyledHeaderBar>
                                <StyledTitleBlock>
                                    <Link
                                        to="/community/dictionary"
                                        style={{ textDecoration: 'none' }}
                                        onClick={() => {
                                            setCrntPage(0);
                                            setOverPage(0);
                                            setScrollDownToggle(false);
                                            setSubTabVisible(true);
                                        }}
                                    >
                                        <StyledLogoImg src="/gardenersLogo.png" />
                                    </Link>
                                </StyledTitleBlock>
                                <HamburgerMenu handleclick={setIsHamburgerMenuOn} />
                                <Sidebar>                                        
                                    <SidebarHeader />
                                    {/* <div className="" style={{display : "flex", justifyContent : "space-between", alignItems : "center"}}>
                                        <img src="/Gardener.png" style={{width : "initial", height : "10vw"}}/>
                                        <img onClick={() => setIsHamburgerMenuOn(prev => !prev)} src="/btnClose.png" style={{width : "initial", height : "10vw"}}></img>
                                    </div> */}
                                    {/* <div className="" style={{display : "flex", flex : "0 0 50%", justifyContent : "space-between",  paddingBottom : "6vw" }}>
                                    </div> */}
                                    <SibebarLayout/>
                                    {/* <ThemeProvider theme={muiTheme}>                                        
                                        <div className="" style={{display : "flex", width : "100%", boxSizing : "border-box" ,gap : "3%", padding : "5vw 0 "}}>
                                            <Link to="/login" style={{width : "48%", textDecoration: 'none', color: '#272727' }}>
                                                <Button variant="outlined"  style={{width : "100%", fontSize : "5vw", lineHeight : "2.5em", fontWeight : 1000 }}>로그인</Button>
                                            </Link>
                                            <Link to="/login" style={{width : "48%", textDecoration: 'none', color: '#272727' }}>
                                                <Button variant="contained" style={{width : "100%", fontSize : "5vw", lineHeight : "2.5em", fontWeight : 1000}}>회원가입</Button>
                                            </Link>
                                        </div>
                                        <hr/>
                                        <div className="" style={{margin : "10vw 0"}}></div>
                                        <details>
                                            <summary>
                                                커뮤니티
                                            </summary>
                                            <div className="" style={{display : "flex", flexDirection : "column", gap : "8vw", padding : "8vw 0 0 0"}}>
                                                <div style={{fontSize : "6vw", fontWeight : "bolder", color : "#D9D9D9"}}>식물사전</div>
                                                <div style={{fontSize : "6vw", fontWeight : "bolder", color : "#D9D9D9"}}>인기</div>
                                                <div style={{fontSize : "6vw", fontWeight : "bolder", color : "#D9D9D9"}}>팔로잉</div>
                                                <div style={{fontSize : "6vw", fontWeight : "bolder", color : "#D9D9D9"}}>사진</div>
                                                <div style={{fontSize : "6vw", fontWeight : "bolder", color : "#D9D9D9"}}>매거진</div>
                                                <div style={{fontSize : "6vw", fontWeight : "bolder", color : "#D9D9D9"}}>Q&A</div>                                                
                                            </div>
                                        </details>
                                        <div style={{fontSize : "6vw", fontWeight : "1000", paddingTop : "7vw" }}>스토어</div>
                                    </ThemeProvider> */}
                                </Sidebar>
                                
                                <StyledMenuItemsContainer style={{ marginLeft: '50px' }}>
                                    {/* <HamburgerIcon /> */}
                                    <StyledMenuItemBlock>
                                        <Link
                                            to="/community/dictionary"
                                            style={{ textDecoration: 'none' }}
                                            onClick={() => {
                                                setScrollDownToggle(false);
                                                setSubTabVisible(true);
                                            }}
                                        >
                                            <StyledMenuItemText color={'#0d6637'}>커뮤니티</StyledMenuItemText>
                                        </Link>
                                    </StyledMenuItemBlock>
                                    <LineStyle />
                                    <StyledMenuItemBlock>
                                        <a
                                            href="https://smartstore.naver.com/gardenersclub"
                                            target="blank"
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <StyledMenuItemText color={'#898989'}>스토어</StyledMenuItemText>
                                        </a>
                                    </StyledMenuItemBlock>
                                </StyledMenuItemsContainer>
                                <StyledButtonsCotainer>
                                    <Link to="/login" style={{ textDecoration: 'none', color: '#272727' }}>
                                        <StyledLoginButton>로그인</StyledLoginButton>
                                    </Link>
                                    <StyledBoundary />
                                    <Link to="/login" style={{ textDecoration: 'none', color: '#272727' }}>
                                        <StyledRegisterButton>회원가입</StyledRegisterButton>
                                    </Link>
                                </StyledButtonsCotainer>
                            </StyledHeaderBar>
                        </StyledHeaderBarContainer>

                        <StyledSubTabBarBlock>
                            {crntPath === 'mypage' ? (
                                <></>
                            ) : (
                                <SubTabBar
                                    visible={!scrollDownToggle}
                                    overPage={overPage}
                                    crntPage={crntPage}
                                    setScrollDownToggle={setScrollDownToggle}
                                    setSubTabVisible={setSubTabVisible}
                                    items={subTabBarItems}
                                />
                            )}
                        </StyledSubTabBarBlock>
                    </StyledTabsContainer>
                    <StyledContentContainer>
                        <StyledContentBlock>{children}</StyledContentBlock>
                    </StyledContentContainer>
                    <Footer />
                </StyledContainer>
            );
    }
};


const headerFadeIn = keyframes`
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
`;

const headerFadeOut = keyframes`
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(-100%);
    }
`;

const StyledLogoImg = styled.img`
    width: 198px;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        position: relative;
        top : 3px;
    }
`;

const StyledSubTabBarBlock = styled.div`
    /* position: fixed; */
    width: 100%;
    top: 80px;
    left: 0px;
    z-index: 1;
    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        display: none;
    }

`;

const StyledLoginButton = styled.div`
    font-size: 15px;
    font-weight: 500;
    color: #272727;
    cursor: pointer;
`;

const StyledBoundary = styled.div`
    width: 1px;
    height: 18px;
    background-color: #eaeaea;
    margin-right: 10px;
    margin-left: 10px;
`;

const StyledRegisterButton = styled.div`
    font-size: 15px;
    font-weight: 500;
    color: #272727;
    cursor: pointer;
    margin-right: 10px;
`;

const StyledButtonsCotainer = styled.div`
    height: 25px;
    display: flex;
    align-items: center;
    @media screen and (max-width : 1353px) {
        display: none;
    }    
    @media screen and (min-width: ${boundaryWidth}px) {
        height: 30px;
    }
`;

const StyledTitleBlock = styled.div`
    @media screen and (max-width: ${boundaryWidth}px) {
        margin-right: auto;
    }
`;

const StyledMenuItemsContainer = styled.div`
    flex: 1;
    display: flex;
    @media screen and (max-width : 1353px) {
        display: none;
    }
`;

const StyledMenuItemTextForSidebar = styled.div<{ color: string }>`
    /* font-size: 18px; */
    /* font-size: 1.4em; */
    position: relative;
    left : 0vw ; 
    font-size: 4.5vw;
    font-weight: bolder;
    cursor: pointer;
    color: ${({ color }) => color};
    /* &:hover {
        color: #0d6637;
    } */
    ::before {
        content: "";
        /* position: absolute; */
        position: relative;
        display: inline-block;
        width: 4px;
        top : 0.5em;
        height: 2em; /* 막대의 높이를 조정하려면 이 값을 수정하세요 */
        background-color: ${({ color }) => color}; /* 막대의 색상을 조정하려면 이 값을 수정하세요 */
        margin-right: .5em; /* 막대와 텍스트 사이의 간격을 조정하려면 이 값을 수정하세요 */

        /* content: "1";
        font-size: .3em;
        line-height: 6em;
        height: 6em;
        background-color: ${({ color }) => color};
        margin-right: 1em;  */
    }
`;

const StyledMenuItemText = styled.div<{ color: string }>`
    font-size: 18px;
    cursor: pointer;
    color: ${({ color }) => color};
    &:hover {
        color: #0d6637;
    }
`;

const StyledMenuItemBlock = styled.h2`
    a:visited {
        color: black;
    }

    @media screen and (max-width: ${720}px) {
        display: none;
    }
`;

const StyledContentBlock = styled.div`
    width: 100%;
`;

const StyledContentContainer = styled.div`
    display: flex;
    z-index: 0;
`;

const StyledLoginContentContainer = styled.div`
    display: flex;
    margin-top: 100px;
    margin-bottom: 100px;
    justify-content: center;
    align-items: center;
    z-index: 0;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        margin-top: 0;
    }
`;

const StyledTabsContainer = styled.div``;

const StyledHeaderBarContainer = styled.div<{ fadeAnim: any }>`
    /* position: fixed; */
    top: 0px;
    left: 0px;
    display: flex;
    z-index: 2;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: white;
    border-bottom: solid 1px;
    border-color: silver;
    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        border: 0;
    }    

`;

const EventBarContainer = styled.div`
    display: flex;
    z-index: 0;
    background-color: #0d6637;
    justify-content: center;
`;

const StyledHeaderEventBar = styled.div<{ color: string }>`
    display: flex;
    height: 50px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    align-items: center;
    font-family: 'Noto Sans KR';
    color: ${({ color }) => color};

    /* @media screen and (max-width: ${1900}px) {
        padding-left: 10%;
        padding-right: 10%;
    }
    @media screen and (min-width: ${1900}px) {
        margin-left: 390px;
        margin-right: 390px;
    } */
`;

const StyledHeaderBar = styled.div`
    display: flex;
    align-items: center;
    width: 1920px;
    height: 80px;
    margin-left: 390px;
    margin-right: 390px;

    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        /* padding-left: 10%; */
        /* padding-right: 10%; */
        box-sizing: border-box;
        width: 100%;
        justify-content: space-between;
        margin-left: 3vw;
        margin-right: 3vw;
        height: 3.4em;
    }

    /*   @media screen and (max-width: ${1900}px) {
        padding-left: 10%;
        padding-right: 10%;
    }
    @media screen and (min-width: ${1900}px) {
        margin-left: 390px;
        margin-right: 390px;
    } */
`;

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    /* width: 1920px; */
    width: 100%;
    /* background: red; */
    z-index: 99;
    @media screen and (max-width: ${1353}px) {
        /* width: 100%; */
        /* display: none; */
    }
`;

const StyledLoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* width: 1920px; */
    width: 100%;
    z-index: 99;
    @media screen and (min-width: ${1800}px) {
        width: 100%;
    }
    background-color: #f5f5f5;
`;

const LineStyle = styled.div`
    display: flex;
    margin: 15px 20px 0 20px;
    height: 40px;
    border-right: 1px solid #f2f2f2;
`;

export default HeaderBar;
