import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const RESPONSIVE_THRESHHOLD = 1353 ; 
const MOBILE_THRESHHOLD = 480 ; 

const Footer: React.FC = () => {
    const navigate = useNavigate();

    

    return (
        <div>
            <style>
                {/* .only_desktop {
                    display : none
                } */}
            </style>
            <StyledFooterContainer>
                <StyledFooterLogo src={'/footerLogo.png'} />
                <StyledTextBlock>
                    <StyledMiddleText onClick={() => navigate('/tos')}>고객이용 약관</StyledMiddleText>
                    <StyledMiddleText onClick={() => navigate('/privacy')}>개인정보처리방침</StyledMiddleText>
                </StyledTextBlock>
                <StyledSmallTextBlock>
                    <StyledSmallText>(주) 가드너스클럽 (대표: 서원재)</StyledSmallText>
                    <StyledBoundary>  |</StyledBoundary>
                    <StyledSmallTextOnlyForDesktop>주소: 세종특별자치시 나성북1로 22, 8층 803-78호(나성동, 디펠리체)</StyledSmallTextOnlyForDesktop>
                    <StyledSmallTextOnlyForMobile>세종특별자치시 나성북1로 22, 8층 803-78호(나성동, 디펠리체)</StyledSmallTextOnlyForMobile>
                </StyledSmallTextBlock>
                <StyledSmallTextBlock>
                    <StyledSmallText>사업자등록번호: 102-87-02659</StyledSmallText>
                    <StyledBoundary> |</StyledBoundary>
                    <StyledSmallTextOnlyForDesktop>이메일: info@gardenersclub.co.kr</StyledSmallTextOnlyForDesktop>
                    <StyledBoundary> |</StyledBoundary>
                    <StyledSmallTextOnlyForDesktop>고객센터: 070-8983-8427</StyledSmallTextOnlyForDesktop>
                    <StyledBoundary> |</StyledBoundary>
                    <StyledSmallTextOnlyForDesktop>(11:00 am - 18:00 pm)/토, 일, 공휴일 휴무</StyledSmallTextOnlyForDesktop>
                </StyledSmallTextBlock>
            </StyledFooterContainer>
        </div>
    );
};

const StyledFooterContainer = styled.div`
    height: 260px;
    box-sizing: border-box;
    width : 100% ;
    /* min-width: 1140px; */
    /* max-width: 1140px; */
    background-color: #494949;
    padding-top: 60px;
    @media screen and (max-width: ${1900}px) {
        padding-left: 10%;
        padding-right: 10%;
    }
    @media screen and (min-width: ${1900}px) {
        padding-left: 390px;
        padding-right: 390px;
    }
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        height : auto ;
        padding-top: 7%;
        padding-bottom: 3%;
        padding-left: 2%;
        padding-right: 2%;
        box-sizing: border-box;
        width: 100%;
        /* margin-left: 2%; */
        /* margin-right: 2%; */

    }    
`;

const StyledFooterLogo = styled.img`
    height: 26px;
    width: 198px;
    margin-bottom: 27px;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        width: 55%;
        height: auto;
    }

`;

const StyledMiddleText = styled.div`
    font-size: 14px;
    color: #ffffff;
    padding-right: 20px;
    color: #999;
    cursor: pointer;
`;

const StyledSmallText = styled.div`
    font-size: 12px;
    color: #ffffff;
    color: #999;
`;

const StyledSmallTextOnlyForMobile = styled.div`
    display: none;
    font-size: 12px;
    color: #ffffff;
    color: #999;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow : ellipsis;
    }
`;


const StyledSmallTextOnlyForDesktop = styled.div`
    font-size: 12px;
    color: #ffffff;
    color: #999;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        display: none;
    }
`;

const StyledBoundary = styled.div`
    font-size: 12px;
    color: #ffffff;
    padding-left: 10px;
    padding-right: 10px;
    color: #999;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        display: none;
    }
`;

const StyledTextBlock = styled.div`
    display: flex;
    margin-bottom: 23px;
`;
const StyledSmallTextBlock = styled.div`
    display: flex;
    margin-bottom: 3px;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        flex-direction : column;
        gap : .5em ;
        margin-bottom: .5em;
    }
`;
export default Footer;
