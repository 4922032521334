
export const subTabBarItems = [
    [
        { name: '식물사전', link: '/community/dictionary/', value: 'dictionary'},
        { name: '인기', value: 'popular'},
        { name: '팔로잉', value: 'following' },
        { name: '사진', value: 'photo' },
        { name: '매거진', value: 'magazine' },
        { name: 'Q&A', value: 'question' },
    ],
];

