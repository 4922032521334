import styled from 'styled-components';
import DailyPlant from '../DailyPlant';

const DailyInfo: React.FC = () => {
    return (
        <StyledDailyInfoContainer>
            <StyledDailyPlantBlock>
                <DailyPlant width="100%" height="100%" borderRadius="0px" imgUrls={['']} />
            </StyledDailyPlantBlock>
        </StyledDailyInfoContainer>
    );
};

const StyledDailyPlantBlock = styled.div`
    width: 100%;
`;

const StyledDailyInfoContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 1920px;
    height: 720px;
`;

export default DailyInfo;
