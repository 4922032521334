import React, { useState } from 'react';
import styled from 'styled-components';
import { IFaq } from './Faq.type';
import * as DOMPurify from 'dompurify';

const RESPONSIVE_THRESHHOLD = 1353 ; 
const MOBILE_THRESHHOLD = 480 ; 

const Faq: React.FC<IFaq> = ({ data }) => {

    const [isActive, setIsActive] = useState([false]);
    const isMobile = navigator.userAgent.toLowerCase().includes(`mobile`)


    function onOpenBtn(index: number) {
        const newIsActive = [...isActive];
        newIsActive[index] = true;
        setIsActive(newIsActive);
    }

    function onCloseBtn(index: number) {
        const newIsActive = [...isActive];
        newIsActive[index] = false;
        setIsActive(newIsActive);
    }

    const faqLIst = [
        {
            title: '관리 난이도(Care level)',
            answer: data?.faqLevel,
        },
        {
            title: '물(Watering)',
            answer: data?.faqWater,
        },
        {
            title: '빛과 공간(Light&Placement)',
            answer: data?.faqLightPlace,
        },
        {
            title: '온도(Temperature)',
            answer: data?.faqTemperature,
        },
        {
            title: '습도(Humidity)',
            answer: data?.faqHumidity,
        },
        {
            title: '독성(Toxicity)',
            answer: data?.faqToxicity,
        },
        {
            title: '비료(Fertilizer)',
            answer: data?.faqFertilizer,
        },
        {
            title: '해충(Pest)',
            answer: data?.faqPest,
            pestInfo: data?.plantContentPestFeedDtoList,
        },
    ];

    return (
        <StyledGuideContainer>
            <StyledGuideTitle>
                {isMobile
                ? <>
                    <div className="" style={{fontSize : "7vw", fontWeight : "bold", fontFamily : "Noto Serif", padding : ".1em 0"}}>FAQ</div>
                    <div className="" style={{width : "max-content"}}>{`:자주 묻는 질문`}</div>
                </>
                : <StyledBoldText>자주 묻는 질문</StyledBoldText>
                }
                
            </StyledGuideTitle>
            {faqLIst.map((item, index) => (
                <StyledQuestionTitleContainer key={index} isClick={isActive[index]}>
                    <StyledQuestionTitleBlock onClick={() => (isActive[index] ? onCloseBtn(index) : onOpenBtn(index))}>
                        <div style={{ display: 'flex' } }>
                            <StyledQuestionIcon>FAQ {index + 1}.</StyledQuestionIcon>
                            <StyledQuestionTitle>{item.title}</StyledQuestionTitle>
                        </div>
                        {isActive[index] ? (
                            <StyledIcon src={'/btnDropUp.png'} />
                        ) : (
                            <StyledIcon src={'/btnDropdown.png'} />
                        )}
                    </StyledQuestionTitleBlock>
                    {isActive[index] && (
                        <>
                            <div>
                                <StyledQuestionBorder />
                                <StyledQuestionAnswer
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(item.answer ?? ''),
                                    }}
                                />
                            </div>
                            {item.pestInfo?.length !== 0 && (
                                <>  <LineStyle />
                                        {item.pestInfo?.map((item, idx) => (
                                            <>
                                                {isMobile
                                                ? (
                                                    <PestBoxContainer key={idx}>
                                                        <div className="" style={{display : "flex", flexDirection : "column"}}>
                                                            <PestImage>
                                                                <img src={item.pestUrl} alt="" width={"100%"}/>
                                                            </PestImage>
                                                            <div className="" style={{display : "flex" , flexDirection : "column"}}>
                                                                <PestName style={{ fontSize: '16px', marginBottom:10}}>
                                                                    {item.pestName}
                                                                </PestName>
                                                                <PestName>설명</PestName>
                                                                <PestText>{item.pestExplanation}</PestText>
                                                            </div>
                                                        </div>
                                                        <PestExplainContainer>
                                                            <PestName>증상</PestName>
                                                            <PestText>{item.pestSymptom}</PestText>
                                                            <PestName>치료</PestName>
                                                            <PestText>{item.pestTherapy}</PestText>
                                                        </PestExplainContainer>
                                                    </PestBoxContainer>
                                                )
                                                : (
                                                    <PestBoxContainer key={idx}>
                                                        <PestImage>
                                                            <img src={item.pestUrl} alt="" />
                                                        </PestImage>
                                                        <PestExplainContainer>
                                                            <PestName style={{ fontSize: '16px', marginBottom:10}}>
                                                                {item.pestName}
                                                            </PestName>
                                                            <PestName>설명</PestName>
                                                            <PestText>{item.pestExplanation}</PestText>
                                                            <PestName>증상</PestName>
                                                            <PestText>{item.pestSymptom}</PestText>
                                                            <PestName>치료</PestName>
                                                            <PestText>{item.pestTherapy}</PestText>
                                                        </PestExplainContainer>
                                                    </PestBoxContainer>
                                                )}
                                            </>
                                        ))}
                                    <LineStyle />
                                </>
                            )}
                        </>
                    )}
                </StyledQuestionTitleContainer>
            ))}
        </StyledGuideContainer>
    );
};

const StyledQuestionAnswer = styled.div`
    padding: 24px 24px 27px;
    font-family: AppleSDGothicNeoM00;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 150%;
    letter-spacing: normal;
    color: #3d3d3d;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        width: 100%;
        box-sizing: border-box;
        max-width: 100vw;
        padding: 24px 15px 27px;

        /* white-space: pre-wrap; */
        /* background: black; */
        div {
          display : block !important
        }
    }

`;
const StyledQuestionBorder = styled.div`
    width: 1092px;
    height: 1px;
    margin: 20px 24px 0px;
    opacity: 0.65;
    background-color: #dadada;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        box-sizing: border-box;
        margin: 20px 15px 0px;
        width: 90%;

    }
`;
const StyledQuestionIcon = styled.div`
    margin: 4px 10px 0px 24px;
    font-family: AppleSDGothicNeo;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0d6637;

    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        margin: 0  ;
    }

    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        /* font-size: 3vw; */
        font-size: 4vw;
        font-weight: bolder;
        min-width: fit-content;
        /* margin: 0px 0px 0px 24px;     */
        margin: 0px 0px 0px 15px;    
    }   

`;

const StyledQuestionTitleContainer = styled.div<{ isClick: boolean }>`
    width: 1140px;
    background-color: #f2f4f6;
    margin-bottom: 16px;
    padding: ${({ isClick }) => (isClick ? '20px 0px 0px' : '20px 0px')};
    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        box-sizing: border-box;
        width: 100%;
        /* width: 90%; */
        /* width: 90vw; */
        /* padding: 20px 20px ; */
        /* padding: 15px 35px ; */

    }

    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        box-sizing: border-box;
        /* width: 100%; */
        width: 91vw;
        padding: ${({ isClick }) => (isClick ? '.8em 0px 0px' : '.8em 0px')};
        /* padding : .8em; */
        /* margin-bottom: 0; */
        /* background: RED; */
    }
`;

const StyledQuestionTitleBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        /* display: none; */
    }
`;

const StyledQuestionTitle = styled.div`
    height: 19px;
    margin: 3px 0px 0px 10px;
    font-family: AppleSDGothicNeo;
    font-size: 16px;
    font-weight: bold;
    color: #454545;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        /* font-size: 3.5vw; */
        font-size: 4vw;
        margin: 0px 0px 0px 10px;
        font-weight: bolder;
    }    
`;

const StyledBoldText = styled.div`
    font-size: 30px;
    font-weight: bold;
    color: #272727;
`;
const StyledGuideContainer = styled.div`
    width: 100%;
    margin-top: 120px;
    margin-bottom: 120px;

    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        box-sizing: border-box;
        width: 100vw;
        margin: 0;
    }

    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        margin-top: 0;
        margin-bottom: 0px;
        width: 100%;
        /* padding : 5vw; */
        padding : 0em 0 0.1em 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
    } 
`;

const StyledGuideTitle = styled.div`
    text-align: center;
    margin-bottom: 34px;
`;

const StyledIcon = styled.img`
    width: 24px;
    height: 24px;
    margin: 0 24px 0px 0px;
    object-fit: contain;
    cursor: pointer;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        margin: 0 15px 0px 0px;   
    }
`;

const PestBoxContainer = styled.div`
    display: flex;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        flex-direction: column;
        margin: 0 3vw;
    }
`;

const PestImage = styled.div`
    padding: 0 24px;
    img {
        width: 280px;
        object-fit: contain;
    }
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        /* padding: 0 3vw; */
        padding : 0 ;
        img {
            /* width: 80px; */
            width: 100%;
            object-fit: contain;   
        }
    }        

`;

const PestExplainContainer = styled.div`
    padding-right: 20px;
    min-height: 250px;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        padding-right: 0px;
    }
`;

const PestName = styled.div`
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 150%;
    letter-spacing: normal;
    color: #3d3d3d;
    margin-bottom: 0px;
    font-family: 'AppleSDGothicNeoB00';
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        font-weight: 1000;
        /* font-weight: 500; */
    }        
`;

const PestText = styled.div`
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 150%;
    letter-spacing: normal;
    color: #3d3d3d;
    margin-bottom: 10px;
    font-family: AppleSDGothicNeoM00;
`;

const LineStyle = styled.div`
    display: none;
    width: 1092px;
    margin: 21px 24px;
    height: 0.5px;
    opacity: 0.65;
    background-color: #dadada;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        width: 100%;
        display: none;
    }    
`;

export default Faq;
