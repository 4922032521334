import React from 'react';
import styled from 'styled-components';

const RESPONSIVE_THRESHHOLD = 1353

const HamburgerIcon = styled.div`
  width: 30px;
  height: 3px;
  /* background-color: #000; */
  background-color: #D6D6D6;
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const HamburgerButton = styled.button`
  /* background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px; */
  display: none;

  /* &:focus {
    outline: none;
  } */
  @media screen and (max-width : 1353px) {
        position: relative;
        /* top : -5px;  */
        top : 0px; 
        display: flex;
        background-color: transparent;
        border: none;
        cursor: pointer;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5px;
        color : #D6D6D6;

        &:focus {
            outline: none;
        }

    }
`;

const HamburgerMenu = ({handleclick} : {handleclick : React.Dispatch<React.SetStateAction<boolean>>}) => {
  return (
    <HamburgerButton onClick={() => {
      handleclick(prev => !prev)
      }}>
      <HamburgerIcon />
      <HamburgerIcon />
      <HamburgerIcon />
    </HamburgerButton>
  );
};

export default HamburgerMenu;
