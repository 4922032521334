import { useState, useEffect, useRef } from 'react';
import { IFilters_Test } from './Filters.type';
import { FaCaretDown } from 'react-icons/fa';
import styled from 'styled-components';
import { createPortal } from 'react-dom';

const RESPONSIVE_THRESHHOLD = 1353 ; 
const MOBILE_THRESHHOLD = 480 ; 

const boundaryWidth = process.env.REACT_APP_BOUNDARY_WIDTH;

const Filters_Test: React.FC<IFilters_Test> = (props) => {
    const { setGetFilter, data } = props;
    const [selected, setSelected] = useState('');
    const [isActive, setIsActive] = useState([false]);
    const [currentItem, setCurrentItem] = useState('');
    const dropdownListRef = useRef<any>(null);
    const sendFilter = () => {
        setGetFilter(selected);
    };
    const [currentFilterModalXPos, setCurrentFilterModalXPos] = useState(0);
    const [currentFilterModalYPos, setCurrentFilterModalYPos] = useState(0);

    const screenWidth = Number(window.innerWidth)

    console.log({selected});
    console.log({isActive});
    console.log({currentItem});
    // console.log(data);
    
    
    useEffect(() => {
        function handleClickOutside(e: MouseEvent): void {
            if (dropdownListRef.current 
                && !dropdownListRef.current.contains(e.target as Node)) {
                setIsActive([false]);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownListRef]);

    const handleCurrentTag = (option: string) => {
        if (currentItem.includes(option)) {
            setCurrentItem('');
        } else {
            setCurrentItem(option);
        }
    };

    function onOpenBtn(index: number) {
        const newIsActive = [...isActive];
        newIsActive[index] = true;
        setIsActive(newIsActive);
    }

    function onCloseBtn(index: number) {
        console.log('onCloseBtn');        
        console.log('onCloseBtn');        
        console.log('onCloseBtn');        
        console.log('onCloseBtn');        
        console.log({index});

        
        const newIsActive = [...isActive];
        newIsActive[index] = false;
        // setIsActive(newIsActive);
        setIsActive([false,false,false,false,false,false,false,false,false,false,]);
    }

    useEffect(() => {
        sendFilter();
    }, [currentItem]);
    
    console.log({isActive});
    console.log({data});
    
    return (
        <StyledFiltersContainer onPointerMove={e => {
            onCloseBtn(0);
        }}>
            <StyledFiltersBlock>
                {data.map((item, index) => {
                    const { id, name, list } = item;
                    return (
                        <StyledDropdown key={id} >
                            <StyledDropdownBtn onClick={(e) => {
                                const x = (e.currentTarget as HTMLElement).getClientRects()[0].x
                                const y = (e.currentTarget as HTMLElement).getClientRects()[0].y
                                
                                setCurrentFilterModalXPos(
                                    (x / window.innerWidth) > 0.62
                                    ? window.innerWidth * 0.62
                                    : x)
                                setCurrentFilterModalYPos(y + window.pageYOffset)                                                
                                
                                onOpenBtn(index)
                            }}>
                                <StyledDropdownText>{name}</StyledDropdownText>
                                <FaCaretDown />
                            </StyledDropdownBtn>
                            {(screenWidth > RESPONSIVE_THRESHHOLD) 
                            && isActive[index] 
                            && (
                                <StyledDropdownContent ref={dropdownListRef}>                                    
                                    {list.map((option: any) => (
                                        <StyledContentItem
                                            key={option}                                      
                                            onClick={(e) => {                                                
                                                handleCurrentTag(option);
                                                setSelected(option);
                                                onCloseBtn(index);

                                            }}
                                        >                                            
                                            {option}
                                        </StyledContentItem>
                                    ))}
                                </StyledDropdownContent>
                            )}
                            {(screenWidth <= RESPONSIVE_THRESHHOLD) 
                            && isActive[index] 
                            && createPortal(
                                <StyledDropdownContentForMobile 
                                    x={currentFilterModalXPos}
                                    y={currentFilterModalYPos}
                                    ref={dropdownListRef}>                                    
                                    {list.map((option: any) => (

                                        <StyledContentItem
                                            key={option}
                                            onClick={(e) => {
                                                handleCurrentTag(option);
                                                setSelected(option);
                                                /* eslint-disable no-debugger */
                                                // debugger;
                                                onCloseBtn(index);

                                            }}
                                        >                                            
                                            {option}
                                        </StyledContentItem>
                                    ))}
                                </StyledDropdownContentForMobile>,
                                document.getElementById('modal-root') as Element 
                            )
                            }                            
                        </StyledDropdown>
                    );
                })}
            </StyledFiltersBlock>
        </StyledFiltersContainer>
    );
};

const StyledFiltersContainer = styled.div``;

const StyledFiltersBlock = styled.div`
    display: flex;
`;

const StyledDropdown = styled.div`
    user-select: none;
    margin-right: 10px;
    position: relative;
`;

const StyledDropdownBtn = styled.div`
    padding: 8px 10px 10px 14px;
    background-color: #f4f4f4;
    font-weight: 400;
    color: gray;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 15px;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        padding: .4em;
    }        
`;

const StyledDropdownText = styled.div`
    padding-right: 2px;
    color: #2a2a2a;
    font-size: 15px;
    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        font-size: 11px;
        width : max-content;
    }    

    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        font-size: 11px;
        width : max-content;
    }    
`;

const StyledDropdownContentForMobile = styled.div<{ x: any, y : any }>`
    position: absolute;
    top: ${({ y }) => `${y + 30}px`} ;
    left: ${({ x }) => {
        console.log('x is ' , x);
        
        return `${x}px`
    }} ;
    background: #fff;
    border: 1px solid lightgrey;
    font-weight: 300;
    color: #333;
    width: 130px;
    z-index: 10;
    font-size: 15px;
    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        z-index: 9999;
    }
`;

const StyledDropdownContent = styled.div`
    position: absolute;
    top: 110%;
    left: 0;
    background: #fff;
    border: 1px solid lightgrey;
    font-weight: 300;
    color: #333;
    width: 130px;
    z-index: 10;
    font-size: 15px;
    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        z-index: 9999;
    }
`;

const StyledContentItem = styled.div`
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s;
    :hover {
        background: lightgray;
    }
`;

export default Filters_Test;
