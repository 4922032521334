import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { UserInfo } from 'recoil/auth';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled as styledMui } from '@mui/system';

const RESPONSIVE_THRESHHOLD = 1353 ; 
const MOBILE_THRESHHOLD = 480 ; 

const REDIRECT_URI_AFTER_LOGIN = 'https://www.gardenersclub.co.kr/register';
// const REDIRECT_URI_AFTER_LOGIN = 'https://localhost:3000/register';

const ImageBanner = styled('div')({
    width: '100%',
    height: '200px',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // background: '#f5f5f5',
    background : 'white',
  });
  
  const BannerImage = styled('img')({
    width: '50%',
    height: 'auto',
  });
  
  const CloseButton = styled(IconButton)({
    position: 'absolute',
    left: 0,
    top: 0,
  });

const Login: React.FC = () => {
    const nav = useNavigate();

    const [loginStatus, setLoginStatus] = useRecoilState(UserInfo);
    const KAKAO_AUTH_URL = `https://www.gardenersclub.co.kr/api/oauth2/authorization/kakao?redirect_uri=${REDIRECT_URI_AFTER_LOGIN}`;
    const NAVER_AUTH_URL = `https://www.gardenersclub.co.kr/api/oauth2/authorization/naver?redirect_uri=${REDIRECT_URI_AFTER_LOGIN}`;

    const handleLogin = () => {
        window.location.href = KAKAO_AUTH_URL;
    };

    const handleNaverLogin = () => {
        window.location.href = NAVER_AUTH_URL;
    };

    const onTalkButton = () => {
        /* eslint-disable no-debugger */
        // debugger ; 
        setLoginStatus({ ...loginStatus, isLogin: true });
        nav('/');
    };

    return (
        <>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                <StyledLoginContainer>
                    <StyledLogoImg src="/Gardener.png" onClick={() => nav('/')} />
                    <StyledBasicText> 지금 바로 간편하게</StyledBasicText>
                    <StyledBasicText>
                        <p style={{ fontWeight: 'bold', color: '#043935' }}>가드너스 클럽</p>
                        <p>에 가입해보세요</p>
                    </StyledBasicText>
                    <StyledLoginImg src="/kakao_login_wide.png" onClick={handleLogin} />
                    <StyleddNaverLogin onClick={handleNaverLogin}>
                        <img src="/naver_logo.png" />
                        <div>네이버로 시작하기</div>
                    </StyleddNaverLogin>
                    <StyledButton onClick={onTalkButton}>
                        <StyledLoginText>로컬로그인</StyledLoginText>
                    </StyledButton>
                </StyledLoginContainer>
            </div>
        </>
    );
};

const StyledLoginImg = styled.img`
    width: 326px;
    height: 48px;
    margin-top: 20px;
    cursor: pointer;
    :hover {
        opacity: 0.9;
    }
`;

const StyleddNaverLogin = styled.div`
    position: relative;
    background-color: #03c75a;
    width: 326px;
    height: 48px;
    margin-top: 20px;
    border-radius: 4px;
    cursor: pointer;
    :hover {
        opacity: 0.9;
    }
    img {
        height: 48px;
    }
    div {
        position: absolute;
        font-size: 15px;
        color: white;
        top: 13px;
        left: 114px;
    }
`;

const StyledBasicText = styled.div`
    font-size: 18px;
    color: #272727;
    display: flex;
`;
const StyledLoginText = styled.div`
    font-size: 20px;
    font-weight: bold;
    color: white;
`;

const StyledButton = styled.div`
    width: 100px;
    height: 100px;
    margin-top: 20px;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: silver;
    &:hover {
        background-color: grey;
    }
`;
 
const StyledLogoImg = styled.img`
    width: 210px;
    padding-bottom: 50px;
`;

const StyledLoginContainer = styled.div`
    margin-top: 108px;
    margin-bottom: 88px;
    width: 420px;
    height: 500px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        box-sizing: border-box;
        width: 100%;
        /* margin-top: 10vh; */
        /* margin-bottom: 10vh; */
        margin-top: 0;
        /* margin-bottom: 0; */
        margin-bottom: 20vh;

    }

`;

export default Login;
