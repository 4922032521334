import DictionaryInfo from 'common/components/DictionaryInfo';
import PlantGuide from 'common/components/PlantGuide';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import { IDictionaryDetailsParams } from 'common/types';
import Faq from 'common/components/FAQ';
import axios from 'axios';
import { DetailReviewInfo } from 'domains/DictionanyDetails/components';
import { IReviewType } from 'domains/DictionanyDetails/components/types/reviewType';
import { ReviewList } from 'domains/DictionanyDetails/components/ReviewList/ReviewList.impl';
import { useInView } from 'react-intersection-observer';

const RESPONSIVE_THRESHHOLD = 1353 ; 
const MOBILE_THRESHHOLD = 480 ; 

const boundaryWidth = process.env.REACT_APP_BOUNDARY_WIDTH;
const maxWidth = process.env.REACT_APP_MAX_WIDTH;

const BASEURL = 'https://www.gardenersclub.co.kr/api';
const TOKEN = localStorage.getItem('accesstoken');

const tabCategory = ['인기순', '최신순', '내가 작성한 리뷰'];

const DictionaryDetails: React.FC = () => {
    const [articleCols, setArticleCols] = useState(window.innerWidth > Number(boundaryWidth) ? 3 : 2);
    const [articleGap, setArticleGap] = useState(window.innerWidth > Number(boundaryWidth) ? 2 : 4);
    const [loading, setLoading] = useState(false);
    const params = useParams();
    const navigate = useNavigate();
    const [details, setDetails] = useState<IDictionaryDetailsParams>();
    const [reviewList, setReviewList] = useState<IReviewType>();
    const [tabData, setTabData] = useState('인기순');
    const [reviewSize, setReviewSize] = useState(5);
    const [reviewRef, reviewInView] = useInView();

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${BASEURL}/api/plantDic/${params.id}/detail`);
            setDetails(response.data.value);
        } catch (e) {
            console.log(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const getReviewData = async () => {
        if (tabData !== '내가 작성한 리뷰') {
            const {
                data: { value },
            } = await axios.get(`${BASEURL}/api/plantDicReview/${params.id}/search`, {
                params: { order: tabData, size: reviewSize},
            });
            setReviewList(value);
        } else {
            const {
                data: { value },
            } = await axios.get(`${BASEURL}/api/plantDicReview/${params.id}/search`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${TOKEN}`,
                },
                params: { order: tabData, size: reviewSize},
            });
            setReviewList(value);
        }
    };

    useEffect(() => {
        getReviewData();
    }, [tabData]);

    useEffect(() => {
        if (reviewInView) {
            if (reviewList?.totalElements ?? 5 <= reviewSize) {
                setReviewSize((prev) => prev + 5);
                getReviewData();
            }
        }
    }, [reviewInView]);

    const activeTab = (tab: string) => {
        setTabData(tab);
        if (tab === '내가 작성한 리뷰' && !TOKEN) {
            navigate('/login');
        }
    };

    return (
        <StyledDicDetailsContainer>            
            <div style={{ maxWidth: 1140, margin: 'auto' }}>
                <DictionaryInfo data={details} />
                <PlantGuide data={details} />
                <Faq data={details} />
                {details && <DetailReviewInfo data={details} requestReview={getReviewData} />}
                <StyledTabContainer>
                    {tabCategory.map((item, idx) => (
                        <StyledTabText key={idx} tabData={tabData} item={item} onClick={() => activeTab(item)}>
                            {item}
                        </StyledTabText>
                    ))}
                </StyledTabContainer>
                <ReviewBox>
                    {reviewList?.content.length !== 0 ? (
                        <>
                            {reviewList?.content?.map((item, idx) => (
                                <StyledReviewListContainer key={idx}>
                                    {details && (
                                        <ReviewList
                                            fetchData={fetchData}
                                            getReviewData={getReviewData}
                                            data={item}
                                            details={details}
                                        />
                                    )}
                                </StyledReviewListContainer>
                            ))}
                        </>
                    ) : tabData !== '내가 작성한 리뷰' ? (
                        <EmptyDataContainer>
                            <div>
                                아직 ‘{details?.plantName}’에 대한 리뷰가 없어요.
                                <br /> 처음으로 이 식물과 함께 한 경험을 공유해주세요!
                            </div>
                        </EmptyDataContainer>
                    ) : (
                        <EmptyDataContainer>
                            <div>
                                혹시 ‘{details?.plantName}’ 집사님이신가요?
                                <br /> 이 식물과 함께한 가드너님의 경험을 공유해주세요!
                            </div>
                        </EmptyDataContainer>
                    )}
                    <div ref={reviewRef} />
                </ReviewBox>
                {}
            </div>
        </StyledDicDetailsContainer>
    );
};

interface IStyled {
    tabData: string;
    item: string;
}

const StyledDicDetailsContainer = styled.div`
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* @media screen and (max-width: ${maxWidth}px) {
        padding-left: 20%;
        padding-right: 20%;
    }
    @media screen and (min-width: ${maxWidth}px) {
        margin-left: 390px;
        margin-right: 390px;
    } */
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        margin-bottom : 0 ;
    }

`;

const StyledDetailsBlock = styled.div`
    width: 1140px;
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
`;

const StyledDetailTitle = styled.div`
    font-family: Noto Sans KR;
    display: flex;
    font-size: 26px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #272727;
    span {
        color: #0d6637;
        font-weight: bold;
    }
`;

const StyledArrowIcon = styled.img`
    width: 14px;
    height: 14px;
    margin: 2px 0 2px 4px;
    object-fit: contain;
`;

const StyledMoreText = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #a6a6a6;
    cursor: pointer;
`;

const StyledTabContainer = styled.div`
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        width: 100%;
        padding: 0 5vw;
        /* margin : 0 ; */
        box-sizing: border-box;
        gap : .3em ;
        /* height: auto ; */
    }

`;

const StyledTabText = styled.span<IStyled>`
    margin-right: 6px;
    color: ${({ tabData, item }) => (tabData === item ? '#0D6637' : '#D9D9D9')};
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
    font-family: Noto Sans KR;
`;

const StyledReviewListContainer = styled.div`
    display: flex;
    /* height: 250px; */
    margin-bottom: 15px;
    /* padding: 10px; */
    padding: 20px 10px ;
    /* padding: 20px 10px 20px 20px; */
    /* padding: 20px 15px; */
    align-items: center;
    gap : 1em;
    /* margin : '1em 0'; */
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        box-sizing: border-box;
        width: 100%;
        flex-direction: column;
        height: auto;
        margin-bottom: 0px;
        padding: 20px 0 ;
        /* overflow-x: hidden; */
    }

`;

const EmptyDataContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 108px;
    margin-bottom: 200px;
    div {
        font-family: 'Noto Sans';
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        color: #d9d9d9;
    }
`;

const ReviewBox = styled.div`
    height: 1000px;
    overflow: overlay;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
        display: none;
    }
`;

export default DictionaryDetails;
