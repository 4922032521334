import { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { ISubTabBar } from './SubTabBar.type';

const RESPONSIVE_THRESHHOLD = 1353 ; 
const MOBILE_THRESHHOLD = 480 ; 

const maxWidth = process.env.REACT_APP_MAX_WIDTH;
const boundaryWidth = process.env.REACT_APP_BOUNDARY_WIDTH;

const SubTabBar: React.FC<ISubTabBar> = (props) => {
    const { visible, overPage, crntPage, setScrollDownToggle, setSubTabVisible, items, justifyContent } = props;
    const [fadeAnim, setFadeAnim] = useState<any>();

    useEffect(() => {
        visible ? setFadeAnim(subTabBarFadeIn) : setFadeAnim(subTabBarFadeOut);
    }, [visible]);

    useEffect(() => {
        setFadeAnim(null);
    }, []);
    return (
        <StyledSubTabBarContainer fadeAnim={fadeAnim}>
            <StyledSubTabBarBlock justifyContent={justifyContent ? justifyContent : ''}>
                {items[overPage].map((item, index) => (
                    <StyledMenuItemBlock key={index} selected={item.value === 'dictionary' ? true : false}>
                        {item.link ? (
                            <Link
                                to={item.link}
                                style={{ textDecoration: 'none' }}
                                onClick={() => {
                                    setScrollDownToggle(false);
                                    setSubTabVisible(true);
                                }}
                            >
                                <StyledMenuItemText color={item.value === 'dictionary' ? '#0d6637' : '#676767'}>
                                    {item.name}
                                </StyledMenuItemText>
                            </Link>
                        ) : (
                            <div
                                style={{ textDecoration: 'none' }}
                                onClick={() => {
                                    setScrollDownToggle(false);
                                    setSubTabVisible(true);
                                }}
                            >
                                <StyledMenuItemText color={item.value === 'dictionary' ? '#0d6637' : '#676767'}>
                                    {item.name}
                                </StyledMenuItemText>
                            </div>
                        )}
                    </StyledMenuItemBlock>
                ))}
            </StyledSubTabBarBlock>
        </StyledSubTabBarContainer>
    );
};

const subTabBarFadeIn = keyframes`
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
`;

const subTabBarFadeOut = keyframes`
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(-100%);
        opacity: 0;
    }
`;

const StyledMenuItemText = styled.h2<{ color: string }>`
    font-size: 16px;
    color: ${({ color }) => color};
    cursor: pointer;
    &:hover {
        color: #0d6637;
    }
`;

const StyledMenuItemBlock = styled.div<{ selected: boolean }>`
    margin-right: 30px;
    border-bottom: solid;
    border-width: ${({ selected }) => (selected ? '3px' : '0px')};
    border-color: #0d6637;
`;

const StyledSubTabBarBlock = styled.div<{ justifyContent: string }>`
    background-color: white;
    width: 1920px;
    display: flex;
    padding : 0 390px ;
    /* margin-left: 390px; */
    /* margin-right: 390px; */
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        width: 100%;
        box-sizing: border-box;
    }

    /*  @media screen and (max-width: ${1900}px) {
        padding-left: 10%;
        padding-right: 10%;
    }
    @media screen and (min-width: ${1900}px) {
        margin-left: 390px;
        margin-right: 390px;
    } */
`;

const StyledSubTabBarContainer = styled.div<{ fadeAnim: any }>`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    background-color: white;
    border-bottom: solid 1px;
    border-color: silver;
    animation: ${({ fadeAnim }) => fadeAnim} 0.1s;
    animation-fill-mode: forwards;
    box-sizing: border-box;
    @media screen and (max-width: ${1900}px) {
        justify-content: start;
    }
    @media screen and (max-width: ${boundaryWidth}px) {
        animation: ${({ fadeAnim }) => fadeAnim} 0.1s;
        animation-fill-mode: forwards;
    }
`;

export default SubTabBar;
