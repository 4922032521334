import React from 'react';
import styled from 'styled-components';

const PersonalInformation: React.FC = () => {
    return (
        <StyledPrivacyContainer>
             <h1>개인정보처리방침</h1>
            <span>
                주식회사 가드너스클럽(이하 “회사”)는 개인정보 보호 관련 법령에 따라 고객의 개인정보를 보호하고, 
                이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립・공개합니다.
            </span>
            <h3>1. 개인정보의 수집 및 이용목적</h3>
            <span>
                <table border={1}>
                    <tr>
                   <th>구분</th>
                   <th>목적</th>
                    </tr>
                   <tr>
                       <td align='center'>회원 가입</td>
                       <td align='center'>회원 가입의사 확인, 이용자 식별 및 본인여부, 회원자격 유지·관리, 계약 이행 및 약관변경 등의 고지를 위한 연락, 본인의사 확인 및 민원 등의 고객 고충 처리, 부정이용 방지, 비인가 사용방지 및 서비스 제공 및 계약의 이행, 서비스 이용 및 상담, 문의, 후기 등 원활한 의사소통 경로 확보, 맞춤형 회원 서비스 제공, 지역 기반 서비스 제공 등</td>  
                   </tr>
                   <tr>
                       <td align='center'>재화/서비스 이용 및 제공</td>
                       <td align='center'>재화/서비스 이용 및 제공   물품배송, 상품 및 주문, 선물하기 등 서비스 제공, 서비스 만족도 조사, 콘텐츠 제공, 맞춤형 상품 및 서비스 제공, 마케팅 활동, 본인인증, 요금결제·정산, 환불 등</td>
                   </tr>
                    <tr>
                       <td align='center'>재화/서비스 이용 및 제공</td>
                       <td align='center'>웹 페이지 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계, 이벤트 등 광고성 정보 전달</td>
                   </tr>
                </table>
            </span>
            <h3>2. 개인정보 수집 항목 및 방법</h3>
            <span>
                회사는 다음의 개인정보 항목을 수집하여 처리하고 있습니다. <div />
                <table border={1}>
                    <tr>
                   <th>목적</th>
                   <th>구분</th>
                   <th>개인정보 항목(필수)</th>
                   <th>개인정보 항목(선택)</th>
                   <th>수집방법</th>
                    </tr>
                   <tr>
                       <td rowSpan={2} align='center'>회원 가입</td>
                       <td align='center' width={150}>카카오 간편가입</td>  
                       <td align='center'>카카오계정(이메일, 프로필 사진), 주소(시/군구/동)</td>  
                       <td align='center'>-</td>  
                       <td rowSpan={2} align='center'>홈페이지</td>
                   </tr>
                   <tr>
                       <td align='center' width={150}>네이버 간편가입</td>
                       <td align='center'>네이버계정(이메일, 프로필 사진), 주소(시/군구/동)</td>  
                       <td align='center'>-</td>  
                    </tr>   
                    <tr>
                       <td rowSpan={3} align='center'>재화 또는 서비스 이용 및 제공</td>
                       <td align='center'>상품 주문</td>
                       <td align='center'>결제정보, 주문자 이름, 주문자 휴대폰 번호, 수취인 이름, 수취인 휴대폰 번호, 주문자 주소, 수취인 주소, 구매상품정보</td>
                       <td align='center'>-</td> 
                       <td rowSpan={3}>홈페이지,, 이메일, 서면양식, 팩스, 고객센터를 통한 전화와 온라인 상담, 이벤트 응모</td>
                   </tr>
                   <tr>
                       <td align='center'>취소 및 환불</td>
                       <td align='center'>예금주, 은행명, 계좌번호</td>  
                       <td align='center'>-</td>  
                       
                   </tr>
                   <tr>
                       <td align='center'>입점신청</td>
                       <td align='center'>신청자 이메일, 담당자 이름, 담당자 연락처, 담당자 이메일</td>
                       <td align='center'>-</td>  
                   </tr>
                </table> <div />
                ※ 회사의 서비스 이용 과정에서 서비스 이용기록, 방문기록, 불량 이용기록, IP주소, 쿠키, MAC주소 등의 정보가 자동으로 생성되어 수집될 수 있습니다. 
            </span>
            <h3>3. 개인정보의 보유 및 이용기간</h3>
            <span>
                ① 회사는 법령에 따른 개인정보 보유·이용 기간 또는 이용자로부터 개인정보 수집 시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.<div />
                ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.<div />
                1) 홈페이지 회원 가입 및 관리 : 회원 탈퇴 시 즉시 파기함. 단, 부정이용 방지를 위하여  <b>30일 동안 보관(아이디, 휴대폰 번호) 후 파기하며, 재화 또는 서비스 구매 회원에 해당하는 경우는 5년</b>동안 보관 후 파기합니다.<div /> 
                다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료시까지. <div /> 
                ﹘관계 법령 위반에 따른 수사 조사 등이 진행중인 경우에는 해당 수사 조사 종료시까지 <div /> 
                ﹘서비스 이용에 따른 채권 채무관계 잔존시에는 해당 채권 채무관계 청산시까지 <div /> 
                2) 개인정보 유효기간제(휴면계정 정책) : 1년 이상 서비스 이용기록이 없는 이용자의 개인정보는 개인정보 보호법 제39조의6에 따라 일반 이용자의 개인정보와 분리(휴면계정으로 전환)하여 저장 및 관리됩니다. 회사는 휴면계정으로 전환되기 30일 이전, 해당 내용에 대해 이메일 등을 통해 이용자에게 사전 통지를 하며 분리 저장된 개인정보는 관련 법령에 특별한 규정이 있는 경우를 제외하고 해당 개인정보를 이용ㆍ제공하지 않습니다.<div /> 
                3) 재화 또는 서비스 제공 : 재화·서비스 공급완료 및 요금결제·정산 완료시까지 <div />
                ﹘본인인증 : 회원 탈퇴시 <div />
                ﹘입점신청 : 신청 후 5년 <div />
                ﹘회원 프로필 : 회원 탈퇴 또는 동의 철회 시 파기 <div />
                ③ 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료시까지 <div />
                4) 14세 미만 아동의 개인정보 처리 <div />
                회사는 법정대리인의 동의가 필요한 만14세 미만 아동에 대한 정보를 수집 및 이용하지 않습니다. <div />
                1) 법령에 의한 정보 보유 <div />
                <table border={1}>
                    <tr>
                   <th>대상</th>
                   <th>관련 법 조항</th>
                   <th>보유기간</th>
                    </tr>
                   <tr>
                       <td align='center' width={750}>계약 또는 청약철회 등에 관한 기록</td>
                       <td align='center' rowSpan={3} width={750}>전자상거래 등에서 소비자 보호에 관한 법률</td>
                       <td align='center' width={150}>5년</td>
                    </tr>
                   <tr>
                      <td align='center'>대금결제 및 재화 등의 공급에 관한 기록</td>
                      <td align='center'>5년</td>
                   </tr>
                    <tr>
                       <td align='center'>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                       <td align='center'>3년</td>
                   </tr>
                   <tr>
                       <td align='center'>본인 확인에 대한 기록</td>
                       <td align='center'>정보통신 이용촉진 및 정보보호 등에 관한 법률</td>
                       <td align='center'>6개월</td>
                   </tr>
                   <tr>
                       <td align='center'>인터넷 로그기록자료, 접속지 추적자료</td>
                       <td align='center'>통신비밀보호법</td>
                       <td align='center'>3개월</td>
                   </tr>
                </table> <div />
                2) 회사 방침에 의한 정보 보유 <div />
                ﹘보유 목적 : 분쟁 조정, 고객문의 대응 및 법령 준수 이력 증빙 <div />
                ﹘보유 항목 : 이메일, 문자, 주소, 알림톡 발송이력(이름, 아이디, 휴대폰 번호, 이메일) <div />
                ﹘보유 기간 : 발송일부터 6개월 <div />
            </span>

            <h3>4. 개인정보의 파기절차 및 방법</h3>
            <span>
                ① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.  <div />
                ② 이용자로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다. 별도 보존되는 개인정보는 법률에 의한 경우가 아니고서는 보존목적 이외의 다른 목적으로 이용되지 않습니다.
                <table border={1}>
                    <tr>
                   <th>제공받는 자</th>
                   <th>제공 항목</th>
                   <th>제공 목적</th>
                   <th>보유 및 이용기간</th>
                    </tr>
                   <tr>
                       <td align='center' width={190}>배송상품 제공업체</td>
                       <td align='center' width={500}>(배송 상품 구매 시)구매자 정보(성명, 휴대폰번호, 주소), 배송일자, 구매상품정보</td>  
                       <td align='center' width={400}>서비스 제공(상품 배송, 설치), 고객 상담 및 불만처리</td>
                       <td align='center' width={510}>업무목적 달성 후 파기(단, 타 법령에따라 법령에서 규정한 기간동안 보존)</td>
                   </tr>
                </table> <div />
                <div />
                 ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다. <div /> <div />
                1) 파기절차  <div />
                ﹘회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다. <div />
                2) 파기방법  <div />
                ﹘이용자의 개인정보는 수집 및 이용목적이 달성되면 지체없이 파기합니다.(여기서 ‘이용목적이 달성된 때’란 철회요청, 서비스계약 만료, 탈퇴 시를 의미.) 다만, 회사 내부 방침 또는 관계 법령에서 정한 보관기간이 있을 경우 일정 기간동안 보관 후 파기 됩니다.
                종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기하고, 전자적 파일 형태로 저장된 기록은 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.  <div />
            </span> <div />

            <h3>5. 장기 미이용 회원에 대한 조치</h3>
            <span>
            장기 미이용회원은 회사의 서비스를 1년간 이용하지 않은 이용자를 말합니다. 회사는 이 회원의 개인정보를 별도로 분리 보관 또는 삭제하고 있으며, 
            분리 보관된 개인정보는 9년간 보관 후 지체없이 파기합니다. <div />
            </span>

            <h3>6. 개인정보의 제3자 제공</h3>
            <span>
            ① 회사는 이용자의 개인정보를 수집 시 명시한 범위 내에서만 개인정보를 처리하며, 원칙적으로 이용자의 개인정보를 제3자에게 제공하지 않습니다.<div />
            ② 단, 원활한 서비스 제공을 위해 상품 구매 시 서비스 제공 업체로 이용자의 개인정보를 제3자 제공에 동의하는 경우에는 서비스 제공 및 배송, 본인확인 등을 위하여 필요한 최소한의 개인정보만을 서비스 제공 업체에게 제공합니다. <div />
            (※ 원활한 서비스 제공을 위해 해당 상품 구매 및 제3자 제공 동의하신 경우에만 개인정보가 제공됩니다.) <div />
            </span> <div />

            <h3>7. 개인정보처리의 위탁</h3> 
            <span>
            ① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.  <div />
            ② 회사는 위탁계약 체결 시 위탁업무 수행목적 외 개인정보 처리금지, 기술적 관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리 감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.  <div />
            <table border={1}>
                    <tr>
                   <th>위탁 받는 자</th>
                   <th>위탁하는 업무의 내용</th>
                    </tr>
                   <tr>
                       <td align='center' width={750}>㈜네이버파이낸셜</td>
                       <td align='center' width={750}>결제 및 정산 서비스</td>  
                   </tr>
                </table> <div />
            </span>
            
            <h3>8. 이용자 및 법정대리인의 권리와 그 행사방법</h3> 
            <span>
            ① 이용자는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.  <div />
            1) 개인정보 열람요구  <div />  <div />
            2) 오류 등이 있을 경우 정정 요구  <div />  <div />
            3) 삭제요구  <div />   <div />
            4) 처리정지 요구  <div />  <div />
            ② 이용자는 홈페이지의 "개인정보수정”, 회원탈퇴" 메뉴 및 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 권리 행사를 하실 수 있으며 회사는 이에 대해 지체없이 조치하겠습니다.  <div />
            ③ 이용자가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.  <div />
            ④ 이용자에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 위임장을 제출하셔야 합니다.  <div />
            ⑤ 이용자는 개인정보 보호 유관법령을 위반하여 회사가 처리하고 있는 이용자 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.  <div />
            </span>

            <h3>9. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</h3> 
            <span>
            ① 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(Cookie)’를 사용합니다. <div />
            ② 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 디바이스 저장장치(플래시메모리, SSD, 하드디스크)에 저장되기도 합니다. <div /> <div />
            1) 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다. <div />
            2) 쿠키의 설치 운영 및 거부 : 이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 웹브라우저별 옵션 선택을 통해 모든 쿠키를 허용 또는 거부 하거나, 쿠키가 저장될 때마다 확인을 거치도록 할 수 있습니다. 쿠키 설치 허용여부를 지정하는 방법은 다음과 같습니다. <div />
            ﹘Chrome : 설정 메뉴 &gt; 고급 설정 표시 선택 &gt; 개인정보-콘텐츠 설정 &gt; 쿠키 수준 설정 <div />
            ﹘Firefox : 옵션 메뉴 &gt; 개인정보 &gt;방문기록-사용자 정의 설정 &gt; 쿠키 수준 설정 <div />
            ﹘Safari : 환경설정 메뉴 &gt; 개인정보 탭 &gt; 쿠키 및 웹 사이트 데이터 수준 설정 <div />
            ﹘Internet Explorer : 도구 메뉴 &gt; 인터넷 옵션 &gt; 개인정보 탭 &gt; 개인정보처리 수준 설정 <div /> <div />
            3) 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다 <div /> <div />
            </span>

            <h3>10. 온라인 맞춤형 광고 서비스</h3> 
            <span>
            회사는 다음과 같이 온라인 맞춤형 광고 사업자가 행태정보를 수집하도록 허용하고 있습니다. <div />
            1) 행태정보를 수집 및 처리하는 광고 사업자: 구글, 네이버 <div />
            2) 행태정보 수집 방법: 이용자가 당사 웹사이트 방문하거나 앱을 실행할 때 자동 수집 및 전송 <div />
            </span>

            <h3>11. 개인정보의 기술적·관리적 보호조치</h3> 
            <span>
            회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다. <div />
            1) 관리적 조치: 내부관리계획 수립 시행, 정기적 직원 교육 등 <div />
            2) 기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치 등 <div />
            3) 물리적 조치: 전산실, 자료보관실 등의 접근통제 등 <div />
            </span>

            <h3>12. 개인정보 보호책임자</h3> 
            <span>
            ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. <div />
            <table border={1}>
                    <tr>
                   <th>개인정보 보호책임자</th>
                   <th>개인정보 민원처리 담당부서</th>
                    </tr>
                   <tr>
                       <td align='center' width={750}><p>성명: 김어진</p> <p>부서: 보안팀</p> <p>이메일: privacy@gardenersclub.co.kr</p></td>   
                       <td align='center' width={750}><p>소속: 고객센터</p>전화번호:070-8983-8427<p></p><p>이메일: info@gardenersclub.co.kr</p></td>
                   </tr>
                </table> <div />
            ② 이용자께서는 회사의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당자에게 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다. 
            </span>

            <h3>13. 이용자의 권익침해에 대한 구제방법</h3> 
            <span>
            ① 이용자는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. <div />
            ※ 아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다. <div />
            ② 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다. <div />
            <table border={1}>
                    <tr>
                   <th>문의처</th>
                   <th>연락처</th>
                   <th>홈페이지</th>
                    </tr>
                   <tr>
                       <td align='center' width={500}>개인정보침해신고센터</td>
                       <td align='center'width={500}>(국번없이) 118</td>
                       <td align='center' width={500}>privacy.kisa.or.kr</td>
                    </tr>
                   <tr>
                   <td align='center'>개인정보분쟁조정위원회</td>
                       <td align='center'>1833-6972</td>
                       <td align='center'>www.kopico.go.kr</td>
                    </tr>
                    <tr>
                    <td align='center'>대검찰청 사이버수사과</td>
                       <td align='center'>(국번없이) 1301</td>
                       <td align='center'>www.spo.go.kr</td>
                   </tr>
                   <tr>
                   <td align='center'>경찰청 사이버범죄 신고시스템(ECRM)</td>
                       <td align='center'>(국번없이) 182</td>
                       <td align='center'>ecrm.cyber.go.kr/minwon/main</td>
                    </tr>
                </table> <div />
            </span>

            <h3>14. 개인정보처리방침의 개정과 그 공지</h3> 
            <span>
            본 개인정보 처리방침을 개정할 경우에는 최소 7일전에 홈페이지 또는 이메일을 통해 변경 및 내용 등을 공지하도록 하겠습니다. 다만 이용자의 소중한 권리 또는 의무에 중요한 내용 변경이 발생하는 경우 시행일로부터 최소 30일 전에 공지하도록 하겠습니다.
            <div />﹘공고일자 : 2023년 5월 4일  <div />
            ﹘시행일자 : 2023년 5월 4일  <div />  <div />
            </span>
        </StyledPrivacyContainer>
    );
};

const StyledPrivacyContainer = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    margin-top: 10px;
    min-width: 1140px;
    padding-left: 20%;
    padding-right: 20%;

    div {
        padding-bottom: 10px;
    }
`;

const StyledPrivacyContent = styled.div`
    padding-left: 30px;
`;
export default PersonalInformation;
