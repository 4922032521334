import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { IReviewModalProps } from './ReviewModal.interface';

import CloseImg from 'assets/icon/close.png';
import GreenCloseImg from 'assets/icon/greenClose.png';
import LogoImg from 'assets/icon/logo.png';
import EmptyIcon from '../../../../assets/icon/emptyHeart.png';
import HeartIcon from 'assets/icon/heart.png';
import Plus from 'assets/icon/plus.png';
import Trash from 'assets/icon/trash.png';
import MainLogo from 'assets/icon/mainLogo.png';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { ReviewDialogModal } from 'common/components/ReviewDialog/ReviewDialog.impl';
import { useParams } from 'react-router-dom';

const RESPONSIVE_THRESHHOLD = 1353 ; 
const MOBILE_THRESHHOLD = 480 ; 

const BASEURL = 'https://www.gardenersclub.co.kr/api';
const TOKEN = localStorage.getItem('accesstoken');

export const ReviewModal: React.FC<IReviewModalProps> = (props) => {
    const { open, onClose, data, requestReview, reviewData } = props;

    const params = useParams();

    const [satisfaction, setSatisfaction] = useState(0);
    const [tagList, setTagList] = useState<string[]>([]);
    const [imgFile, setFileImg] = useState<any>();
    const [requestFile, setRequestFile] = useState<any>();
    const [contents, setContents] = useState('');
    const [error, setError] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const isMobile = navigator.userAgent.toLowerCase().includes(`mobile`)

    useEffect(() => {
        if (reviewData) {
            setSatisfaction(reviewData.evaluation);
            setFileImg(reviewData.reviewUrl);
            setContents(reviewData.reviewText);
            setTagList(reviewData.tagDtoList);
        }
    }, [reviewData]);

    const fileInput = useRef<any>(null);

    const initData = () => {
        if (!reviewData) {
            setSatisfaction(0);
            setTagList([]);
            setFileImg(undefined);
            setRequestFile(undefined);
            setContents('');
            setError(false);
        }
    };

    const initClose = () => {
        initData();
        onClose();
    };

    const openConfirmModal = () => {
        setConfirmModal(true);
    };

    const handleConfirmClose = () => {
        onClose();
        initClose();
        setConfirmModal(false);
    };

    const reviewSubmit = async () => {
        const formData = new FormData();
        const uploaderData = JSON.stringify({
            plantDicId: Number(params.id),
            reviewId: reviewData?.reviewId,
            evaluation: satisfaction,
            reviewText: contents,
            tagDtoList: tagList,
        });
        formData.append('file', requestFile);
        formData.append('plantReviewSaveDto', new Blob([uploaderData], { type: 'application/json' }));
        if (satisfaction !== 0 && imgFile !== undefined && tagList.length >= 1 && contents !== '') {
            if (contents.length <= 500) {
                await axios.post(`${BASEURL}/api/plantDicReview/picture/save`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${TOKEN}`,
                    },
                });
                initClose();
                requestReview();
            } else {
                alert('500자 이내로 입력해 주세요.');
            }
        } else {
            setError(true);
        }
    };

    const updateReview = async () => {
        const formData = new FormData();
        const uploaderData = JSON.stringify({
            plantDicId: Number(params.id),
            reviewId: reviewData?.reviewId,
            evaluation: satisfaction,
            reviewText: contents,
            tagDtoList: tagList,
        });
        formData.append('file', requestFile);
        formData.append('plantReviewSaveDto', new Blob([uploaderData], { type: 'application/json' }));
        if (satisfaction !== 0 && imgFile !== undefined && tagList.length >= 1 && contents !== '') {
            if (contents.length > 20) {
                await axios.put(`${BASEURL}/api/plantDicReview/update`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${TOKEN}`,
                    },
                });
                initClose();
                requestReview();
            } else {
                alert('20자 이상으로 입력해 주세요.');
            }
        } else {
            setError(true);
        }
    };

    // useEffect(() => {
    //     if (open) document.body.style.overflow = 'hidden';
    //     if (!open) document.body.style.overflow = 'unset';
    // }, [open]);

    const handleSatisfaction = (score: number) => {
        setSatisfaction(score);
    };

    const selectTag = (tag: string) => {
        for (const i in tagList) {
            if (tagList[i] === tag) {
                return false;
            }
        }
        setTagList(tagList.concat(tag));
    };

    const removeTag = (tag: string) => {
        setTagList(tagList.filter((item) => item !== tag));
    };

    const handleFileUpload = () => {
        fileInput.current.click();
    };

    const changeFile = (e: any) => {
        console.log(e.target.files[0]);
        if (!e.target.files || e.target.files.length === 0) {
            setFileImg(undefined);
            return;
        }
        setFileImg(URL.createObjectURL(e.target.files[0]));
        setRequestFile(e.target.files[0]);
    };

    const removeFile = () => {
        setFileImg(undefined);
        setRequestFile(undefined);
    };

    const modules = {
        toolbar: false,
    };

    const renderEvaluationSum = () => {
        if (satisfaction === 0) {
            return (
                <>
                    <StyledEmptyImg onClick={() => handleSatisfaction(1)} src={EmptyIcon} />
                    <StyledEmptyImg onClick={() => handleSatisfaction(2)} src={EmptyIcon} />
                    <StyledEmptyImg onClick={() => handleSatisfaction(3)} src={EmptyIcon} />
                    <StyledEmptyImg onClick={() => handleSatisfaction(4)} src={EmptyIcon} />
                    <StyledEmptyImg onClick={() => handleSatisfaction(5)} src={EmptyIcon} />
                </>
            );
        }
        if (satisfaction === 1) {
            return (
                <>
                    <StyledHeartImg src={HeartIcon} alt="" />
                    <StyledEmptyImg onClick={() => handleSatisfaction(2)} src={EmptyIcon} alt="" />
                    <StyledEmptyImg onClick={() => handleSatisfaction(3)} src={EmptyIcon} alt="" />
                    <StyledEmptyImg onClick={() => handleSatisfaction(4)} src={EmptyIcon} alt="" />
                    <StyledEmptyImg onClick={() => handleSatisfaction(5)} src={EmptyIcon} alt="" />
                </>
            );
        }
        if (satisfaction === 2) {
            return (
                <>
                    <StyledHeartImg onClick={() => handleSatisfaction(1)} src={HeartIcon} alt="" />
                    <StyledHeartImg src={HeartIcon} alt="" />
                    <StyledEmptyImg onClick={() => handleSatisfaction(3)} src={EmptyIcon} alt="" />
                    <StyledEmptyImg onClick={() => handleSatisfaction(4)} src={EmptyIcon} alt="" />
                    <StyledEmptyImg onClick={() => handleSatisfaction(5)} src={EmptyIcon} alt="" />
                </>
            );
        }
        if (satisfaction === 3) {
            return (
                <>
                    <StyledHeartImg onClick={() => handleSatisfaction(1)} src={HeartIcon} alt="" />
                    <StyledHeartImg onClick={() => handleSatisfaction(2)} src={HeartIcon} alt="" />
                    <StyledHeartImg src={HeartIcon} alt="" />
                    <StyledEmptyImg onClick={() => handleSatisfaction(4)} src={EmptyIcon} alt="" />
                    <StyledEmptyImg onClick={() => handleSatisfaction(5)} src={EmptyIcon} alt="" />
                </>
            );
        }
        if (satisfaction === 4) {
            return (
                <>
                    <StyledHeartImg onClick={() => handleSatisfaction(1)} src={HeartIcon} alt="" />
                    <StyledHeartImg onClick={() => handleSatisfaction(2)} src={HeartIcon} alt="" />
                    <StyledHeartImg onClick={() => handleSatisfaction(3)} src={HeartIcon} alt="" />
                    <StyledHeartImg src={HeartIcon} alt="" />
                    <StyledEmptyImg onClick={() => handleSatisfaction(5)} src={EmptyIcon} alt="" />
                </>
            );
        }
        if (satisfaction === 5) {
            return (
                <>
                    <StyledHeartImg onClick={() => handleSatisfaction(1)} src={HeartIcon} alt="" />
                    <StyledHeartImg onClick={() => handleSatisfaction(2)} src={HeartIcon} alt="" />
                    <StyledHeartImg onClick={() => handleSatisfaction(3)} src={HeartIcon} alt="" />
                    <StyledHeartImg onClick={() => handleSatisfaction(4)} src={HeartIcon} alt="" />
                    <StyledHeartImg src={HeartIcon} alt="" />
                </>
            );
        }
    };

    return (
        <>
            <ModalContainer open={open}>
                <BackdropStyle open={open} />
                <DialogStyle open={open}>
                    <div className="" style={{display : "flex", justifyContent : "space-between", alignItems : "center", borderTop : "1px solid #D6D6D6" , borderBottom : "1px solid #D6D6D6", marginTop : "1.2em", marginBottom : "2em", padding : ".4em 0"}}>
                        {/* <img onClick={() => {1}} src="/btnClose.png" style={{width : "8%"}}></img> */}
                        <CloseBox onClick={openConfirmModal}>
                            <CloseIconStyle src={CloseImg} />
                        </CloseBox>                                                
                        <div className="" style={{position : "relative", left : "-42vw", fontSize : "1.2em",  fontWeight : 1000}}>리뷰 쓰기</div>
                    </div>
                    {/* <DialogHeaderWrapperStyle>
                        <CloseBox onClick={openConfirmModal}>
                            <CloseIconStyle src={CloseImg} />
                        </CloseBox>                        
                    </DialogHeaderWrapperStyle> */}
                    <DialogBodyStyle>
                        <LogoBoxStyle>
                            <div>
                                <img src={LogoImg} />
                            </div>
                            <h4>가드너스 리뷰 - {data.plantName}</h4>
                        </LogoBoxStyle>
                        <PlantInfoContainerStyle>
                            <PlantImgBoxStyle>
                                <div className="" style={{height : "100%", display : "flex", alignItems : "center"}}>
                                    {isMobile
                                    ? <img src={data.plantContentFeedDtoList[0]?.plantPicUrl} style={{width : "20vw", height : "20vw" , objectFit : "cover"}} />
                                    : <img src={data.plantContentFeedDtoList[0]?.plantPicUrl} style={{objectFit : "cover"}} />
                                    }
                                    {/* <img src={data.plantContentFeedDtoList[0]?.plantPicUrl} style={{width : "20vw", height : "20vw" , objectFit : "cover"}} /> */}
                                </div>                                                                
                            </PlantImgBoxStyle>
                            <div>
                                <PlantInfoBoxStyle>
                                    <InfoTextStyle>{data.scientificName}</InfoTextStyle>
                                </PlantInfoBoxStyle>
                                <KoNameStyle>{data.plantName}</KoNameStyle>
                                {!isMobile &&
                                <>
                                    <CateGoryStyle>출신(origin)</CateGoryStyle>
                                    <InfoTextStyle>: {data?.distribution}</InfoTextStyle>
                                    <CateGoryStyle>분류(Taxonomy)</CateGoryStyle>
                                    <InfoTextStyle>
                                        : {data?.korClass}({data?.enClass}) - {data?.korOrder}({data?.enOrder}) -{' '}
                                        {data?.korFamily}({data?.enFamily})
                                    </InfoTextStyle>
                                </>
                                }
                            </div>
                        </PlantInfoContainerStyle>
                        <div style={{ paddingInline: '40px' }}>
                            <LineStyle />
                        </div>
                        {/* <div className="" style={{ borderTop : "0.1px solid #D6D6D6"}}></div> */}
                        <EvaluationContainerStyle>
                            <EvaluationTextStyle error={error} style={{ marginBottom: '15px' }}>
                                평가하기(필수)
                                {error && <ErrorText>필수 입력 항목입니다.</ErrorText>}
                            </EvaluationTextStyle>
                            <SatisfactionBoxStyle>
                                <SatisfactionTextStyle>만족도</SatisfactionTextStyle>
                                {renderEvaluationSum()}
                            </SatisfactionBoxStyle>
                            <EvaluationTextStyle error={error} style={{ marginBottom: '18px' }}>
                                키워드 선택(필수) {error && <ErrorText>필수 입력 항목입니다.</ErrorText>}
                            </EvaluationTextStyle>
                            <KeyWordContainerStyle>
                                <KeyWordBoxStyle onClick={() => selectTag('성장 속도: 빠름')}>
                                    성장 속도: 빠름
                                </KeyWordBoxStyle>
                                <KeyWordBoxStyle onClick={() => selectTag('생존력: 강함')}>
                                    생존력: 강함
                                </KeyWordBoxStyle>
                                <KeyWordBoxStyle onClick={() => selectTag('해충: 약함')}>해충: 약함</KeyWordBoxStyle>
                                <KeyWordBoxStyle onClick={() => selectTag('관리 난이도: 쉬움')}>
                                    관리 난이도: 쉬움
                                </KeyWordBoxStyle>
                                <KeyWordBoxStyle onClick={() => selectTag('잎의 크기: 큼')}>
                                    잎의 크기: 큼
                                </KeyWordBoxStyle>
                                <KeyWordBoxStyle onClick={() => selectTag('잎의 갈변: 잦음')}>
                                    잎의 갈변: 잦음
                                </KeyWordBoxStyle>
                            </KeyWordContainerStyle>
                            <EvaluationTextStyle error={error} style={{ marginBottom: '11px' }}>
                                선택한 키워드 {error && <ErrorText>필수 입력 항목입니다.</ErrorText>}
                            </EvaluationTextStyle>
                            <SelectKeywordContainer>
                                {tagList.map((item, idx) => (
                                    <SelectKeyWordBoxStyle key={idx}>
                                        {item}
                                        <img src={GreenCloseImg} onClick={() => removeTag(item)} />
                                    </SelectKeyWordBoxStyle>
                                ))}
                            </SelectKeywordContainer>
                        </EvaluationContainerStyle>
                        <div style={{ paddingInline: '40px' }}>
                            <LineStyle />
                        </div>
                        <EvaluationContainerStyle>
                            <EvaluationTextStyle error={error} style={{ marginBottom: '15px', marginTop: '5px' }}>
                                사진 첨부(필수) {error && <ErrorText>필수 입력 항목입니다.</ErrorText>}
                            </EvaluationTextStyle>
                            <TextStyle>가드너 님의 정원 속 함꼐 하는 식물의 모습을 공유해 주세요.(최대 1장)</TextStyle>
                            <FileUploadContainer>
                                <FileStyle onClick={() => (!imgFile ? handleFileUpload() : null)}>
                                    {!imgFile ? (
                                        <PlusIconStyle src={Plus} />
                                    ) : (
                                        <>
                                            <PlantImgStyle src={imgFile} />
                                            <div onClick={removeFile}>
                                                <TrashIcon src={Trash} />
                                            </div>
                                        </>
                                    )}
                                </FileStyle>
                                <FileUploadButton
                                    accept="image/jpg,image/png,image/jpeg,image/gif"
                                    ref={fileInput}
                                    onChange={changeFile}
                                    type="file"
                                />
                            </FileUploadContainer>
                            <EvaluationTextStyle error={error} style={{ marginBottom: '15px' }}>
                                리뷰 작성(필수) {error && <ErrorText>필수 입력 항목입니다.</ErrorText>}
                            </EvaluationTextStyle>
                            <QuillWrapper
                                value={contents.substring(0, 500)}
                                onChange={(content: string, delta: any, source: any, editor: any) =>
                                    setContents(editor.getHTML())
                                }
                                modules={modules}
                                theme="snow"
                                placeholder="다른 가드너를 위해 당신의 식물과 함께 한 시간들에 대해 자세히 공유해주세요. (최소 20자)"
                            />
                            <StyledReviewButton onClick={() => (!reviewData ? reviewSubmit() : updateReview())}>
                                리뷰 제출하기
                            </StyledReviewButton>
                        </EvaluationContainerStyle>
                        <GuideContainer>
                            <GuideTitle>
                                <img src={MainLogo} />
                                리뷰 작성 가이드
                            </GuideTitle>
                            <GuideContent>
                                · 식물과 함께 한 가드너님의 경험을 공유해주세요. 당신의 경험은 다른 가드너들에게 소중한
                                정보가 될 수 있습니다.
                            </GuideContent>
                            <GuideContent>
                                · ‘가드너스클럽 식물사전’은 식물을 사랑하는 모든 가드너들을 위한 공간입니다. 타인에 대한
                                비난 및 명예를 훼손하는 게시물, 음란 및 혐오성 게시물, 광고성 게시물 등 공간의 취지에
                                부합하지 않은 게시물의 공유를 금합니다.
                            </GuideContent>
                            <GuideContent>
                                · 식물의 모습이 잘 보이는 사진 이미지와 함께 그에 대한 자세한 설명을 작성해주세요.
                            </GuideContent>
                            <GuideContent>
                                · 리뷰 내용(글)과 첨부하는 이미지가 타인의 지적 재산권을 침해하지 않도록 유의해주세요.
                            </GuideContent>
                        </GuideContainer>
                    </DialogBodyStyle>
                </DialogStyle>
            </ModalContainer>
            {confirmModal && (
                <ReviewDialogModal open={confirmModal} onClose={setConfirmModal} resetBtn={handleConfirmClose} />
            )}
        </>
    );
};

interface IStyled {
    open?: boolean;
}

const ModalContainer = styled.div<IStyled>`
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4000;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: ${({ open }) => (open ? 1 : 0)};
    transition: all 0.3s;
    pointer-events: all;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        width: 100%;
        box-sizing: border-box;
    }    
`;

const BackdropStyle = styled.div<IStyled>`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
`;

const DialogStyle = styled.div<IStyled>`
    background: #fff;
    width: 780px;
    height: auto;
    overflow-y: scroll;
    box-shadow: 0 6px 20px 0 rgb(0 0 0 / 10%);
    min-width: 376px;
    max-width: calc(100vw - 32px);
    max-height: calc(100vh - 32px);
    display: flex;
    flex-direction: column;
    font-size: 14px;
    opacity: ${({ open }) => (open ? 1 : 0)};
    transform: ${({ open }) => (open ? 'translateY(0) translateZ(0)' : 'translateY(40px) translateZ(0)')};
    transition: all 0.3s;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        box-sizing: border-box;
        position: fixed;
        top : 0 ;
        left: 0;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        margin: 0;
        padding: 0;
        transform: 0;
        /* width: 100%;
        min-width : 100%;
        max-width: calc(100vw);
        height: 100vh; */
        /* min-height : 100vh; */
        /* max-height: 100vh; */
        /* z-index: 999999999; */
    }

`;

const DialogHeaderWrapperStyle = styled.div<IStyled>`
    display: flex;
    justify-content: flex-end;
    padding: 10px 0 0 0;
    width: 100%;
    height: 50px;
`;

const CloseBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        /* margin-right: 0; */
    }    
`;

const CloseIconStyle = styled.img`
    width: 18px;
    height: 18px;
    cursor: pointer;
`;

const DialogBodyStyle = styled.div<IStyled>`
    width: 100%;
`;

const LogoBoxStyle = styled.div`
    padding: 0 40px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    div {
        img {
            width: 180px;
            height: 36px;
        }
    }
    h4 {
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        color: #0f3934;
        font-family: Noto Sans KR;
    }
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        padding: 0 4.2vw;
        width: 100%;
        box-sizing: border-box;
    }        
`;

const PlantInfoContainerStyle = styled.div`
    padding: 0 40px;
    display: flex;
    width: 655px;
    height: 160px;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        padding: 0 4.2vw;
        box-sizing: border-box;
        width: 100%;
        height : auto ;
    }        
`;

const PlantImgBoxStyle = styled.div`
    margin-right: 12px;
    width: 160px;
    height: 160px;
    img {
        width: 160px;
        height: 160px;
        object-fit: cover;
    }
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        box-sizing: border-box;
        margin-right: 6px;
        width: 21.3vw;
        height: auto;
        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }    
`;

const PlantInfoBoxStyle = styled.div`
    margin-top: 5px;
    font-family: Noto Sans KR;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        margin-top: 0;
    }
`;

const InfoTextStyle = styled.div`
    margin-bottom: 6px;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    font-family: Noto Sans;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        /* width: 100%; */
        /* font-size: 1px; */
    }

`;

const KoNameStyle = styled.div`
    margin-bottom: 6px;
    font-weight: 700;
    font-size: 16px;
    font-family: Noto Sans KR;
`;

const CateGoryStyle = styled.div`
    margin-bottom: 3px;
    font-weight: 600;
    font-size: 11px;
    line-height: 150%;
    font-family: Noto Sans KR;
`;

const LineStyle = styled.hr`
    margin: 15px 0;
    width: 97.5%;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        position: relative;
        left : -6vw ;
        width: 92vw;
        color : #D6D6D6;
        height: 0.1px;
        background-color: rgba(214,214,214,0.3);
        /* background-color: #D6D6D6; */
        border: none; /* 기본 테두리 제거 */
        /* border-top: "1px solid #D6D6D6"; */

    }
`;

const LineStyleForPictureAdded = styled.hr`
    margin: 15px 0;
    width: 97.5%;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        position: relative;
        left : -6vw ;
        width: 86vw;
        color : #D6D6D6;
        height: 0.3px;
        background-color: #D6D6D6;
        border: none; /* 기본 테두리 제거 */

    }
`;

const EvaluationContainerStyle = styled.div`
    padding: 0 40px;
    width: 100%;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        padding: 0 4.2vw;
        width: 100%;
        box-sizing: border-box;

    }    
`;

const EvaluationTextStyle = styled.div<{ error: boolean }>`
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    font-family: Noto Sans KR;
    color: ${({ error }) => (!error ? '#000' : '#F06060')};
`;

const SatisfactionBoxStyle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 30px;

`;

const SatisfactionTextStyle = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    margin-right: 20px;
    font-family: Noto Sans KR;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        /* width: 100%; */
        width: auto;
        box-sizing: border-box;
    }    
`;

const StyledHeartImg = styled.img`
    width: 32px;
    height: 32px;
    cursor: pointer;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
    }

`;

const StyledEmptyImg = styled.img`
    margin-right: 3px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        /* width: 1em;
        height: 1em; */
    }
`;

const KeyWordContainerStyle = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
`;

const KeyWordBoxStyle = styled.div`
    margin: 0 3px 10px 0;
    padding: 5px 15px 3px 15px;
    background: #d9d9d9;
    border-radius: 19px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    font-family: Noto Sans KR;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        padding: 1vw 4vw;
    }
`;

const SelectKeywordContainer = styled.div`
    padding: 12px 13px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 9px;
    width: 655px;
    border: 1px solid #d9d9d9;
    min-height: 30px;
    border-radius: 5px;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        width: 100%;
        box-sizing: border-box;
        padding : 4vw 4.2vw 2vw 4.2vw;
        min-height: 50px;
    }

`;

const SelectKeyWordBoxStyle = styled.div`
    margin: 0 3px 10px 0;
    padding: 3px 8px 5px 17px;
    border: 1px solid #0d6637;
    border-radius: 14px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #0d6637;
    img {
        margin-left: 10px;
        width: 12px;
        height: 12px;
        cursor: pointer;
    }
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        padding: 2vw 4vw;
        /* margin: 0; */
        /* line-height: auto; */

    }
`;

const TextStyle = styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 10px;
    font-family: Noto Sans KR;
`;

const FileUploadContainer = styled.div`
    display: flex;
    margin-bottom: 32px;
`;

const FileUploadButton = styled.input`
    display: none;
`;

const FileStyle = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 160px;
    border-radius: 0px 0px 5px 5px;
    background: #f5f5f5;
    border-radius: 0px 0px 5px 5px;
    cursor: pointer;
`;

const PlusIconStyle = styled.img`
    width: 36px;
    height: 36px;
`;

const PlantImgStyle = styled.img`
    width: 160px;
    height: 160px;
    object-fit: cover;
`;

const TrashIcon = styled.img`
    position: absolute;
    top: 8px;
    right: 8px;
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const StyledReviewButton = styled.div`
    width: 680px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    background: #0d6637;
    border-radius: 5px;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    color: #fff;
    cursor: pointer;
    font-family: Noto Sans KR;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        width: 100%;
        box-sizing: border-box;
    }

`;

const ErrorText = styled.span`
    position: relative;
    bottom: 4px;
    margin-left: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #f06060;
    font-family: Noto Sans KR;

`;

const GuideContainer = styled.div`
    padding-top: 23px;
    width: 100%;
    background: #d9d9d9;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        padding-bottom: 1em;
    }
`;

const GuideTitle = styled.div`
    padding: 0 40px;
    margin-bottom: 16px;
    font-family: 'Noto Sans';
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    img {
        margin-right: 4px;
        width: 165px;
        height: 24px;
    }
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        padding: 0 4.2vw;
        width: 100%;
        box-sizing: border-box;        
        /* margin-bottom: 2em; */
    }        
`;

const GuideContent = styled.div`
    padding: 0 30px 10px 40px;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        padding: .5em 4.2vw;
    }        
`;

const QuillWrapper = styled(ReactQuill).attrs(() => ({
    theme: 'snow',
}))`
    width: 680px;
    height: 210px;
    display: flex;
    flex-direction: column;
    margin-bottom: 38px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        width: 100%;
    }

`;
