import React from 'react';
import styled from 'styled-components';
import { IPlantGuideProps } from './PlantGuide.interface';
import * as DOMPurify from 'dompurify';

const RESPONSIVE_THRESHHOLD = 1353 ; 
const MOBILE_THRESHHOLD = 480 ; 

const data = {
    index: ['1', '2', '3', '4'],
    korIndex: ['공간', '물주기', '비료', '해충'],
    engIndex: ['Placement', 'Water', 'Fertilizer', 'Pest'],
    picUrl: ['1', '2', '3', '4'],
    text: [
        '동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리 나라만세 무궁화 삼천리 화려강산, 대한사람 대한으로 길이 보전하세',
        '물주기설명',
        '비료설명',
        '해충설명',
    ],
};

const PlantGuide: React.FC<IPlantGuideProps> = ({ data }) => {

    const isMobile = navigator.userAgent.toLowerCase().includes(`mobile`)
    
    return (
        <StyledGuideContainer>
            <StyledGuideTitle>
                {isMobile
                ? <>
                    <div className="" style={{fontSize : "7vw", fontWeight : "bold", fontFamily : "Noto Serif", padding : ".1em 0"}}>Plant Guide</div>
                    <div className="" style={{}}>{`:${data?.plantName}`}</div>
                </>
                : <StyledBoldText>PLANT GUIDE</StyledBoldText>
                }                
            </StyledGuideTitle>
            <StyledGuideBlock>
                <StyledPlaceBlock>

                    {/* <StyledPlantGuideWrapper>
                        <StyledPlantGuideLeftWrapper>
                                <StyledIconImg src={'/placeIcon.png'} />
                                <StyledText>공간</StyledText>
                        </StyledPlantGuideLeftWrapper>
                        <StyledVerticleBorder />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <StyledExplainText
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.plantGuideSpace ?? '') }}
                            />
                        </div>                        
                    </StyledPlantGuideWrapper> */}
                    
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <StyledIconImg src={'/placeIcon.png'} />
                                <StyledText>공간</StyledText>
                        </div>
                        <StyledVerticleBorder />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <StyledExplainText
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.plantGuideSpace ?? '') }}
                            />
                        </div>
                    </div>
                </StyledPlaceBlock>
                <StyledWaterBlock>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <StyledIconImg src={'/waterIcon.png'} />
                            <StyledText>물</StyledText>
                        </div>
                        <StyledVerticleBorder />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <StyledExplainText
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.plantGuideWater ?? '') }}
                            />
                        </div>
                    </div>
                </StyledWaterBlock>
                <StyledFertilizerBlock>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <StyledIconImg src={'/fertilizerIcon.png'} />
                            <StyledText>비료</StyledText>
                        </div>
                        <StyledVerticleBorder />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <StyledExplainText
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(data?.plantGuideFertilizer ?? ''),
                                }}
                            />
                        </div>
                    </div>
                </StyledFertilizerBlock>
                <StyledPestBlock>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <StyledIconImg src={'/antiBugIcon.png'} />
                            <StyledText>해충</StyledText>
                        </div>
                        <StyledVerticleBorder />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <StyledExplainText
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.plantGuidePest ?? '') }}
                            />
                        </div>
                    </div>
                </StyledPestBlock>
            </StyledGuideBlock>
        </StyledGuideContainer>
    );
};

const StyledDetailBtn = styled.div`
    width: 94px;
    height: 30px;
    margin: 18px 0px 0px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0d6637;
    cursor: pointer;
`;

const StyledBtnText = styled.span`
    font-family: NotoSansKR;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
`;

const StyledIconImg = styled.img`
    width: 70px;
    height: 70px;
    margin-bottom: 18px;
    object-fit: contain;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        width: 35px;
        height: 35px;
    }
`;

const StyledText = styled.div`
    font-family: NotoSansKR;
    font-size: 20px;
    font-weight: bold;
    color: #272727;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        font-size: .9em;
    } 
`;

const StyledExplainText = styled.div`
    font-family: NotoSansKR;
    font-size: 16px;
    color: #424242;
    line-height: 150%;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        font-size: .7em;
    }     
`;

const StyledVerticleBorder = styled.div`
    width: 1px;
    height: 90px;
    margin: 13px 32px 18px;
    background-color: #e3e3e3;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        margin: 0 1em ;
    }
`;
const StyledBoldText = styled.div`
    font-size: 30px;
    font-weight: bold;
    font-family: 'BodoniXt';
    color: #272727;
    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        font-family: Noto Serif;
}
`;
const StyledGuideContainer = styled.div`
    width: 100%;
    margin-top: 120px;
    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        width: 100vw;
        margin-top: 2em;
    }

    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        margin-top: 3em;
    }    
`;

const StyledGuideTitle = styled.div`
    text-align: center;
`;

const StyledGuideBlock = styled.div`
    /* width: 1140px; */
    max-width: 1140px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 30px;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        width: auto;
        margin-left : 3vw ;
        margin-right : 3vw ;
        flex-direction: column;
    }    
`;

const BasicBlock = styled.div`
    width: 49%;
    height: 190px;
    box-sizing: border-box;
    padding: 37px 33px 32px 32px;
    box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px #e7e7e7;
    background-color: #fff;
    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        height: auto;
    }

    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        width: 100%;
        height: auto;
        padding:  .6em;
    }
`;

const StyledPlantGuideWrapper = styled.div`
    display: flex ; 
    align-items: center; 
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        display: grid;
        grid-template-columns: 1fr 1fr 5fr;
    } 
`

const StyledPlantGuideLeftWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        flex : 10;
    }

`

const StyledPlantGuideRightWrapper = styled.div`
    display: flex;
    align-items: center;
`

const StyledPlaceBlock = styled(BasicBlock)`
    margin-bottom: 20px;
    margin-right: 20px;

    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        display: flex;
        margin-right: 0px;
    }

    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        margin-bottom: 10px ;
        /* margin-right: 0px; */
    }    
`;

const StyledWaterBlock = styled(BasicBlock)`
    margin-bottom: 20px;

    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        margin-right: 0px;
    }

    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        margin-bottom: 10px ;
    }    
`;

const StyledFertilizerBlock = styled(BasicBlock)`
    margin-right: 20px;

    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        margin-right: 0px;
    }

    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        margin-bottom: 10px ;
    }
`;

const StyledPestBlock = styled(BasicBlock)`
    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        display: flex;
    }
`;

export default PlantGuide;
