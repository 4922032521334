import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HeaderBar } from 'domains';
import {
    Register,
    Dictionary,
    DictionaryDetails,
    Login,
    LoginProcess,
    RegisterProcess,
    PersonalInformation,
    Tos
} from 'pages';
import ScrollToTop from 'common/funcs';

const Navigation: React.FC = () => {
    return (
        <Router>
            <ScrollToTop />
            <HeaderBar>
                <Routes>
                    <Route path="/" element={<Dictionary />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/login/oauth2/token/gardener" element={<LoginProcess />} />
                    <Route path="/community" element={<Dictionary />} />
                    <Route path="/community/dictionary" element={<Dictionary />} />
                    <Route path="/community/dictionary/details/:id" element={<DictionaryDetails />} />
                    <Route path="/register/oauth2/token/gardener" element={<RegisterProcess />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/tos" element={<Tos/>} />
                    <Route path="/privacy" element={<PersonalInformation />} />
                </Routes>
            </HeaderBar>
        </Router>
    );
};

export default Navigation;
