import styled from 'styled-components';
import { IItemList } from './ItemList.type';

const RESPONSIVE_THRESHHOLD = 1353 ; 
const maxWidth = process.env.REACT_APP_MAX_WIDTH;

const ItemList: React.FC<IItemList> = (props) => {
    const { width, imgHeight, horizontalGap, verticalGap, cols, items, RenderComponent, setFunc } = props;
    const length = String((100 - (cols - 1) * horizontalGap) / cols - 0.1) + '%';
    return (
        
        // <StyledItemListContainer width={width}>
        <StyledItemListContainer width={width}>
    {/* // itemlistsx   */}
        {/* // <StyledItemListContainer width='100%'> */}
            {items.map((item: any, index: number) => (
                <StyledItemBlock
                    key={index}
                    length={length}
                    marginRight={(index + 1) % cols === 0 ? 0 : horizontalGap}
                    marginBottom={verticalGap}
                >
                    <RenderComponent
                        width="100%"
                        paddingBottom={imgHeight}
                        item={item}
                        setFunc={setFunc}
                        items={items}
                    />
                </StyledItemBlock>
            ))}
        </StyledItemListContainer>
    );
};

const StyledItemBlock = styled.div<{ length: string; marginRight: number; marginBottom: number }>`
    width: 365px;
    /* margin-right: ${({ marginRight }) => marginRight}%; */
    margin-bottom: ${({ marginBottom }) => (marginBottom !== 0 ? marginBottom - 0.5 : ``)}px;
    margin : 0 0% 5% 0% ;


    @media screen and (min-width: ${maxWidth}px) {
        /* margin-right: ${({ marginRight }) => marginRight}%; */
        margin-bottom: ${({ marginBottom }) => marginBottom}px;
    }

    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        width : auto ; 
    }

    @media screen and (max-width: 480px) {
        width: 44%;
        /* background: red; */
        margin : 0 1% 10% 1% ;
    }    
`;

const StyledItemListContainer = styled.div<{ width?: string }>`
    display: flex;
    justify-content: space-between;
    /* justify-content: space-around; */
    /* justify-content: space-evenly; */
    /* gap : '5em' ; */
    flex-wrap: wrap;
    /* width: ${({ width }) => width}; */
    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        /* gap: 30px; */
        justify-content: space-evenly;
    }    
    
`;

export default ItemList;
