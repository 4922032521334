import { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { IDictionaryItem } from 'common/types';
import { Link, useNavigate } from 'react-router-dom';
import { api } from 'common/api';

const MOBILE_THRESHHOLD = 480 ;
const RESPONSIVE_THRESHHOLD = 1353 ; 
const maxWidth = process.env.REACT_APP_MAX_WIDTH;

const BASEURL = 'https://www.gardenersclub.co.kr/api';
const TOKEN = localStorage.getItem('accesstoken');

const DictionaryItem: React.FC<IDictionaryItem> = (props) => {
    const navigate = useNavigate();
    const { width, height, paddingBottom, item, setFunc, items } = props;

    const [imgAnim, setImgAnim] = useState<any>();

    const onPhotoScrap = async () => {
        if (!TOKEN) {
            navigate('/login');
        } else {
            try {
                await api.post(`${BASEURL}/api/plantDic/${item.plantDicId}/scrap`);

                setFunc(
                    items.map((it) => (it.plantDicId === item.plantDicId ? { ...it, myScrap: !item.myScrap } : it)),
                );
            } catch (e) {
                console.log(e);
            }
        }
    };

    return (
        // <StyledDictionaryItemContainer width={width} height={height} paddingBottom={paddingBottom}>
        <StyledDictionaryItemContainer  paddingBottom={paddingBottom}>
            <Link to={`./details/${item.plantDicId}`} style={{ textDecoration: 'none' }}>
                <StyledImageBlock
                    onMouseEnter={() => {
                        setImgAnim(ImageScaleUp);
                    }}
                    onMouseLeave={() => {
                        setImgAnim(ImageScaleDown);
                    }}
                >
                    <StyledImg src={item.plantPicUrl} width="100%" height="100%" imgAnim={imgAnim} />
                </StyledImageBlock>
            </Link>

            <StyledTitleBlock>
                <div>
                    <StyledEngTitleText>{item.scientificName}</StyledEngTitleText>
                    <StyledTitleText>{item.plantName}</StyledTitleText>
                </div>
                {item.myScrap ? (
                    <StyledIcon src={'/btnBookmark.png'} onClick={() => onPhotoScrap()} />
                ) : (
                    <StyledIcon src={'/btnBlankBookmark.png'} onClick={() => onPhotoScrap()} />
                )}
            </StyledTitleBlock>
        </StyledDictionaryItemContainer>
    );
};

const ImageScaleUp = keyframes`
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
`;

const ImageScaleDown = keyframes`
    0% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
`;

const StyledIcon = styled.img`
    width: 30px;
    height: 30px;
    cursor: pointer;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        width: 5vw;
        height: auto;
    }
`;
const StyledEngTitleText = styled.div`
    font-family: NotoSansKR;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 6px;
    color: #919191;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        font-size: 2.5vw;
    }
`;
const StyledTitleText = styled.div`
    font-family: NotoSansKR;
    font-size: 20px;
    font-weight: bold;
    color: #272727;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        font-size: 3vw;
        font-weight: 1000;
    }
`;

const StyledTitleBlock = styled.div`
    /* position: absolute; */
    /* top: 83%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 15%;
    padding-top: 2em;
    padding-right: 5px;
    box-sizing: border-box;
    background-color: white;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        /* width: 100%; */
        padding-top: 0;
    }  
`;

const StyledImg = styled.img<{ imgAnim: any }>`
    cursor: pointer;
    object-fit: cover;
    animation: ${({ imgAnim }) => imgAnim} 0.2s;
    animation-fill-mode: forwards;
    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        /* width : auto ; */
        /* width : 43vw ; */
    }
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        /* width : auto ; */
        width : 43vw ;
    }    
`;

const StyledImageBlock = styled.div`
    /* position: absolute; */
    overflow: hidden;
    width: 366px;
    /* max-width: 50%; */
    height: 368px; 
    @media screen and (max-width: 480px) {
        width : 100% ;
        height: auto;
        /* width: 40%; */
    }    
`;

const StyledDictionaryItemContainer = styled.div<{ width?: string; height?: string; paddingBottom?: string }>`
    /* position: relative; */
    width: 366px;
    /* max-width: 40%; */
    /* height: 463px; */
    padding-bottom: 0%;
    @media screen and (max-width: 480px) {
        /* width: 50%; */
        width : auto ;
        /* background: red; */
        /* width: calc(366/2)px;
        height: calc(463/2)px; */
    }
`;

export default DictionaryItem;
