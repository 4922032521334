import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { IDictionaryBanner } from './DictionaryBanner.type';
import { Link, useNavigate } from 'react-router-dom';

const MOBILE_THRESHHOLD = 480 ; 
const RESPONSIVE_THRESHHOLD = 1353 ; 

const DictionaryBanner: React.FC<IDictionaryBanner> = (props) => {
    const { data } = props;
    const slideRef = useRef(document.createElement('img'));
    const navigate = useNavigate();
    const [slidePage, setSlidePage] = useState<number>(0);
    const [slideIdx, setSlideIdx] = useState<number>(0);

   const isMobile = navigator.userAgent.toLowerCase().includes(`mobile`)

    const browserWidth = Math.min(window.innerWidth, 1140) ;
    const widthDivide100 = browserWidth / 100 ; 
    const Slidetransform = slidePage / widthDivide100;

    const leftButton = () => {
        if (slidePage > 0) {
            setSlidePage((prev) => prev - browserWidth);
            setSlideIdx((prev) => prev - 1);
        }
    };

    const rightButton = () => {
        if (3 * browserWidth > slidePage) {
            setSlidePage((prev) => prev + browserWidth);
            setSlideIdx((prev) => prev + 1);
        }
    };

    useEffect(() => {
        slideRef.current.style.transition = 'all 0.5s ease-in-out';
        slideRef.current.style.transform = `translateX(-${Slidetransform}%)`;
    }, [slideIdx]);

    // function truncate(text: string) {
    //     const replaced = text.replace(/\n/g, ' ');
    //     if (replaced.length <= 180) {
    //         return replaced;
    //     }
    //     return replaced.slice(0, 180).concat('...');
    // }

    return (
        <div style={{ position: 'relative', overflow: 'hidden' }}>
            <StyledSlideButtonBox>
                <StyledArrowStyle onClick={leftButton}>
                    <img src="/prev.png" />
                </StyledArrowStyle>
                <StyledSlashImg src="/slash.png" />
                <StyledArrowStyle onClick={rightButton}>
                    <img src="/next.png" />
                </StyledArrowStyle>
            </StyledSlideButtonBox>
            <StyleBannerBoxStyle ref={slideRef}>
                {data.slice(0, 4).map((item, idx) => (
                    <StyledMainBannerContainer key={idx} slidePage={slidePage}>                        
                        <StyledImageContainer onClick={() => navigate(`./details/${item.plantDicId}`)}>
                            <img src={item.plantPicUrl} alt="" />
                        </StyledImageContainer>                        
                        {isMobile && 
                        <div className="" style={{display : "flex", flexDirection : "row-reverse"}}>
                            <StyledTextStyleForMobile>Editors's Pick</StyledTextStyleForMobile>
                        </div>
                        }
                        <StyledContentContainer onClick={() => navigate(`./details/${item.plantDicId}`)}>
                            {/* {ismobi} */}
                            {! isMobile && <StyledTextStyle>Editors's Pick</StyledTextStyle> }
                            {/* <StyledTextStyle>Editors's Pick</StyledTextStyle> */}
                            <StyledEnglishName>{item.scientificName}</StyledEnglishName>
                            <StyledKoreanName>{item.plantName}</StyledKoreanName>
                            <StyledKeywordContainer>
                                {item.classification_flower !== 'null' && (
                                    <StyledKeywordBox>{item.classification_flower}</StyledKeywordBox>
                                )}
                                {item.classification_fruit !== 'null' && (
                                    <StyledKeywordBox>{item.classification_fruit}</StyledKeywordBox>
                                )}
                                {item.classification_leaf !== 'null' && (
                                    <StyledKeywordBox>{item.classification_leaf}</StyledKeywordBox>
                                )}
                                {item.classification_succulent !== 'null' && (
                                    <StyledKeywordBox>{item.classification_succulent}</StyledKeywordBox>
                                )}
                                {item.toxicityHarmless !== 'null' && (
                                    <StyledKeywordBox>{item.toxicityHarmless}</StyledKeywordBox>
                                )}
                                {item.toxicitySeriousness !== 'null' && (
                                    <StyledKeywordBox>{item.toxicitySeriousness}</StyledKeywordBox>
                                )}
                                {item.toxicitySlight !== 'null' && (
                                    <StyledKeywordBox>{item.toxicitySlight}</StyledKeywordBox>
                                )}
                                {item.toxicityIngestion !== 'null' && (
                                    <StyledKeywordBox>{item.toxicityIngestion}</StyledKeywordBox>
                                )}
                                {item.toxicitySkin !== 'null' && (
                                    <StyledKeywordBox>{item.toxicitySkin}</StyledKeywordBox>
                                )}
                                {item.cat !== 'null' && <StyledKeywordBox>{item.cat}</StyledKeywordBox>}
                                {item.dog !== 'null' && <StyledKeywordBox>{item.dog}</StyledKeywordBox>}
                                {item.classification_succulent !== 'null' && (
                                    <StyledKeywordBox>{item.classification_succulent}</StyledKeywordBox>
                                )}
                                {item.difficulty !== 'null' && <StyledKeywordBox>{item.difficulty}</StyledKeywordBox>}
                                {item.difficulty !== 'null' && <StyledKeywordBox>{item.difficulty}</StyledKeywordBox>}
                                {item.growSpeed !== 'null' && <StyledKeywordBox>{item.growSpeed}</StyledKeywordBox>}
                            </StyledKeywordContainer>
                        </StyledContentContainer>
                    </StyledMainBannerContainer>
                ))}
            </StyleBannerBoxStyle>
            <StyledDotBox>
                {data.slice(0, 4).map((_, idx) => (
                    <StyledDot
                        key={idx}
                        slideIdx={slideIdx}
                        idx={idx}
                        onClick={() => {
                            setSlideIdx(idx);
                            setSlidePage(idx * browserWidth);
                        }}
                    >
                        <span></span>
                    </StyledDot>
                ))}
            </StyledDotBox>

        </div>
    );
};

interface IStyled {
    slidePage?: number;
    slideIdx?: number;
    idx?: number;
}


const StyleBannerBoxStyle = styled.div`
    width: 100%;
    max-width: 1140px;
    display: flex;
    margin: 40px 0px 0px 0px;

    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        margin: 0;
    }

    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        box-sizing: border-box;
        margin: 0 ;
        width: 100%;
        /* height: 62.9vw; */
    }        
`;

const StyledMainBannerContainer = styled.div<IStyled>`
    position: relative;
    display: flex;
    height: 480px;
    background-color: #f8f8f8;
    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        /* height: 62.9vw; */
        flex-direction: column;
    }
`;

const StyledImageContainer = styled.div`
    position: relative;
    cursor: pointer;
    width: 763px;
    height: 100%;
    img {
        width: 763px;
        height: 100%;
        object-fit: cover;
    }
    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        width: 100vw;
        img {
            width: 100vw;
            height: 62.9vw;
            /* height: 100%; */
            /* object-fit: cover; */
        }

        /* flex-direction: column; */
        /* display: none; */
    }
`;

const StyledSlideButtonBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 40px;
    padding: 11px 16px;
    width: 91px;
    height: 38px;
    background-color: white;
    z-index: 100;
    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        /* flex-direction: column; */
        display: none;
    }
`;

const StyledSlashImg = styled.img`
    transform: rotate(-30deg);
    height: 80%;
`;

const StyledArrowStyle = styled.span`
    font-size: 30px;
    color: #4a4a4a;
    line-height: 150%;
    padding-top: 5px;
    cursor: pointer;
    :hover {
        color: #9b9b9b;
    }
    img {
        width: 17px;
    }
`;

const StyledContentContainer = styled.div`
    box-sizing: border-box;
    padding: 28px 41px 0 17px;
    width: 377px;
    cursor: pointer;
    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        padding: 0px 41px 0 17px;
    }
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        padding: 0px 41px 0 17px;
    }
`;

const StyledTextStyleForMobile = styled.h3`
    margin: 0 196px 40px 0;
    width: 100%;
    height: 29px;
    font-size: 20px;
    font-weight: bold;
    line-height: 150%;
    color: #0d6637;


    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        position : relative;
        top : -0.3em ; 
        /* right : -65vw; */
        /* top : -2.1em ; */
        background: #0d6637;
        color : white;
        font-family: Noto Serif;
        font-weight: 100;
        width: fit-content;
        margin: 0;
        padding: .0em .7em;
        font-size: 1em;
        height: auto;
        font-family: 'Times New Roman', Times, serif;
    }
`;

const StyledTextStyle = styled.h3`
    margin: 0 196px 40px 0;
    width: 100%;
    height: 29px;
    font-size: 20px;
    font-weight: bold;
    line-height: 150%;
    color: #0d6637;

    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        margin: 1em 0 0 0;
    }

    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        position : relative;
        right : -65vw;
        top : -2.1em ;
        background: #0d6637;
        color : white;
        font-family: Noto Serif;
        font-weight: 100;
        width: fit-content;
        margin: 0;
        padding: .0em .7em;
        font-size: 1em;
        height: auto;
        font-family: 'Times New Roman', Times, serif;
    }
`;

// const StyledEnglishName = styled.h4`
const StyledEnglishName = styled.div`
    width: 319px;
    margin: 40px 158px 11px 0;
    font-family: NotoSansKR;
    font-size: 18px;
    font-weight: 500;
    line-height: 150%;
    color: #8c8c8c;
    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        margin: 1em 0 0 0;
    }
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        margin: .4em 158px 11px 0;
    }
`;

const StyledKoreanName = styled.h2`
    width: 319px;
    margin: 11px 106px 16px 0;
    font-family: NotoSansKR;
    font-size: 28px;
    font-weight: bold;
    line-height: 150%;
    color: #272727;
`;

const StyledDotBox = styled.div`
    display: flex;
    overflow: hidden;
    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        justify-content: center;
        /* flex-direction: column; */
        /* display: none; */
    }
`;

const StyledDot = styled.div<IStyled>`
    min-width: ${({ slideIdx, idx }) => (slideIdx === idx ? '25px' : '10px')};
    height: 10px;
    margin: 30px 10px 0 0;
    border-radius: 5px;
    background-color: ${({ slideIdx, idx }) => (slideIdx === idx ? '#0d6637' : '#d8d8d8')};
    cursor: pointer;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        margin: 1em 7px 0 0;

    }
`;

const StyledLine = styled.div`
    width: 1140px;
    height: 1px;
    margin: 40px 0px;
    background-color: #ececec;
`;

const StyledKeywordContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: ${RESPONSIVE_THRESHHOLD}px) {
        width: 100vw;
        padding-bottom: .7em;
        /* gap: 1em; */

    }    

`;

const StyledKeywordBox = styled.div`
    margin: 0 9px 8px 0;
    padding: 6px 12px;
    border-radius: 16px;
    border: solid 1px #dedede;
    background-color: #fff;
    font-size: 14px;
    @media screen and (max-width: ${MOBILE_THRESHHOLD}px) {
        margin : .1em .3em;
        padding : .5em .8em;
        font-size: 11px;
        font-weight: bold;
    }
`;

export default DictionaryBanner;
